import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@application/translation/translate.service';
import {Country} from '@domain/country-enum';
import {IdName} from '@infrastructure/id-name';
import {BaseComponent} from '@presentation/base-component';
import {isNil, snakeCase} from 'lodash-es';

@Component({
  templateUrl: 'address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss']
})
export class AddressDialogComponent extends BaseComponent implements OnInit {
  public title: string;
  public address: any;
  public countries: any;
  public provinces: any;
  public cities: any;
  public districts: any;
  public showProvinceOptions = false;
  public formGroup: FormGroup;
  public data: any;

  private readonly _formBuilder: FormBuilder;
  private readonly _translateService: TranslateService;
  private readonly _dialogRef: MatDialogRef<AddressDialogComponent>;
  private defaultCountry = Country.CHINA;

  public constructor(formBuilder: FormBuilder, translateService: TranslateService, dialogRef: MatDialogRef<AddressDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    super();
    this._formBuilder = formBuilder;
    this._translateService = translateService;
    this._dialogRef = dialogRef;
    this.data = data;
    this.title = data.title;
  }

  public ngOnInit(): void {
    this.setAddressFormFields();
  }

  public onCountrySelected(countryId: number): void {
    this.showProvinceOptions = countryId === this.defaultCountry;
    this.formGroup.controls.province.setValue(null);
    this.onProvinceSelected(null);
  }

  public onProvinceSelected(provinceId: number): void {
    this.cities = !isNil(provinceId) ? this.provinces.find((province: any) => province.id === provinceId).cities : [];
    this.formGroup.controls.city.setValue(null);
    this.onCitySelected(null);
    this.formGroup.controls.city[this.cities.length > 0 ? 'enable' : 'disable']();
  }

  public onCitySelected(cityId: number): void {
    this.districts = !isNil(cityId) ? this.cities.find((city: any) => city.id === cityId).districts : [];
    this.formGroup.controls.district.setValue(null);
    this.formGroup.controls.district[this.districts.length > 0 ? 'enable' : 'disable']();
  }

  public save(): void {
    this.addDynamicValidators();
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      if (isNil(this.getControl('country').value)) {
        this.address = null;
      } else {
        const address = this.data.address;
        address.country = this.countries.find((country: any) => country.id === this.getControl('country').value);
        address.street = this.getControl('street').value;
        address.postalCode = this.getControl('postalCode').value;

        if (address.country.id === this.defaultCountry) {
          const selectedProvince = this.provinces.find((province: any) => province.id === this.getControl('province').value);
          const selectedCity = this.cities.find((city: any) => city.id === this.getControl('city').value);
          address.province = new IdName(selectedProvince.id, selectedProvince.name);
          address.city = new IdName(selectedCity?.id, selectedCity?.name);
          address.district = selectedCity.districts?.find((district: any) => district.id === this.getControl('district').value);
        }

        this.address = address;
      }

      this._dialogRef.close({isConfirmed: true, data: this.address});
    }
  }

  public clearForm(): void {
    this.formGroup.controls.country.setValue(null);
    this.formGroup.controls.province.setValue(null);
    this.formGroup.controls.city.setValue(null);
    this.formGroup.controls.district.setValue(null);
    this.formGroup.controls.street.setValue(null);
    this.formGroup.controls.postalCode.setValue(null);
    this.formGroup.markAsUntouched();

    this.address = null;
    this.onCountrySelected(null);
  }

  public getAddressErrorMessage(controlName: string): string {
    let errorMessage = '';
    const entityName = this._translateService.instant(`ADDRESS.${snakeCase(controlName).toUpperCase()}`).toLowerCase();

    if (this.formGroup.get(controlName).hasError('required')) {
      errorMessage = this._translateService.instant('GENERAL.ERRORS.REQUIRED', {entityName});
    }
    return errorMessage;
  }

  private setAddressFormFields(): void {
    if (isNil(this.address)) {
      this.address = this.data.address;
    }

    if (this.address?.country?.id === this.defaultCountry) {
      this.showProvinceOptions = true;

      if (this.address.province?.id) {
        this.cities = this.provinces.find((region: any) => region.id === Number(this.address?.province?.id)).cities;

        if (this.address.city?.id) {
          this.districts = this.cities.find((city: any) => city.id === Number(this.address?.city?.id))?.districts;
        }
      }
    }

    this.formGroup = this._formBuilder.group({
      country: [this.address?.country?.id],
      province: [this.address?.province?.id],
      city: [{value: this.address?.city?.id, disabled: isNil(this.cities) || this.cities.length === 0}],
      district: [{value: this.address?.district?.id, disabled: isNil(this.districts) || this.districts.length === 0}],
      street: [this.address?.street],
      postalCode: [this.address?.postalCode]
    });
  }

  private addDynamicValidators(): void {
    if (!isNil(this.getControl('country').value)) {
      this.getControl('street').setValidators([Validators.required]);
      this.getControl('postalCode').setValidators(this.getControl('country').value !== this.defaultCountry ? [Validators.required] : []);

      if (this.getControl('country').value === this.defaultCountry) {
        this.getControl('province').setValidators([Validators.required]);

        if (this.cities.length > 0) {
          this.getControl('city').setValidators([Validators.required]);
        }

        if (this.districts.length > 0) {
          this.getControl('district').setValidators([Validators.required]);
        }
      }
    } else {
      this.getControl('street').clearValidators();
      this.getControl('postalCode').clearValidators();
      this.getControl('province').clearValidators();
      this.getControl('city').clearValidators();
      this.getControl('district').clearValidators();
    }

    this.getControl('street').updateValueAndValidity();
    this.getControl('postalCode').updateValueAndValidity();
    this.getControl('province').updateValueAndValidity();
    this.getControl('city').updateValueAndValidity();
    this.getControl('district').updateValueAndValidity();
  }

  private getControl(controlName: string): AbstractControl {
    return this.formGroup.controls[controlName];
  }
}
