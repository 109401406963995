import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightSearchPipe implements PipeTransform {
  public transform(value: string, args: string): string {
    if (!args) {
      return value;
    }
    const regExp = new RegExp(args, 'gi');
    return value.replace(regExp, '<mark>$&</mark>');
  }
}
