import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CanActivateDefaultPathGuard} from '@application/helper/route-guard/can-activate-default-path.guard';
import {RouteUtils} from '@application/routing/route-utils';
import {UnauthorizedPageComponent} from '@presentation/pages/status-codes/401-unauthorized-page.component';
import {SharedModule} from '@shared/shared.module';
import {CommonLayoutComponent} from './presentation/layouts/common-layout/common-layout.component';
import {commonLayoutRoutes} from './shared/routes/common-layout.routes';

export const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [CanActivateDefaultPathGuard],
    component: UnauthorizedPageComponent
  },
  {
    path: RouteUtils.paths.authentication.path,
    loadChildren: (): any => import('@presentation/pages/authentication/authentication.module').then((m: any) => m.AuthenticationModule)
  },
  {
    path: '',
    component: CommonLayoutComponent,
    children: commonLayoutRoutes
  },
  {
    path: '',
    loadChildren: (): any => import('@presentation/pages/status-codes/status-codes.module').then((m: any) => m.StatusCodesModule)
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRouting {}
