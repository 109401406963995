export const regExps: {[key: string]: RegExp} = {
  cnPhone: /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[235-8]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|66\d{2})\d{6}$/,
  myPhone: /^(?:\+?60)?1[0-46-9]*[0-9]{7,8}$/,
  otp: /^[0-9]{6}$/,
  digit: /^\d$/,
  noWhiteSpaces: /^\S*$/,
  noEnglishCharacters: /^[\u4E00-\u9FFF]*$/,
  onlyEnglishCharacters: /^[a-zA-Z_ ']*$/,
  date: /^\d{4}([-])\d{2}\1\d{2}$/,
  digitsWithHyphen: /^[\d\-]+$/,
  digits: /^[\d]*$/,
  noChineseCodeCharacter: /^[^\s\u4E00-\u9FFF]*$/,
  onlyContainsWhiteSpaces: /\S/,
  alphanumericChinese: /^[a-zA-Z0-9\u4e00-\u9fff ]*$/,
  invalidAlphanumericChinese: /[^a-zA-Z0-9\u4e00-\u9fff\s]/
};
