import {InjectionToken} from '@angular/core';
import {AppSettings} from '@application/configuration/appsettings';

export const APPLICATION_CONFIGURATION = new InjectionToken<IAppConfiguration>('IAppConfiguration');

export interface IAppConfiguration {
  load(): Promise<void>;

  get(): AppSettings;

  getValue(key: any): any;
}
