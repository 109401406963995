import {Component, OnInit} from '@angular/core';
import {ILoadingOverlayAngularComp} from 'ag-grid-angular';
import {ILoadingOverlayParams} from 'ag-grid-community';
import {isNil} from 'lodash-es';
import {OverlayComponentParams} from '../overlay/overlay-component-params';
import {OverlayComponent} from '../overlay/overlay.component';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: '../overlay/overlay.component.html',
  styleUrls: ['../overlay/overlay.component.scss']
})
export class LoadingOverlayComponent extends OverlayComponent implements ILoadingOverlayAngularComp, OnInit {
  private params: OverlayComponentParams;

  public agInit(params: any): void {
    this.params = params;
  }

  public ngOnInit(): void {
    if (isNil(this.params)) {
      this.params = this.createParamsObject();
    }

    this.initializeOverlayComponent(this.params);
    this.title = this.getTranslation('OVERLAY.LOADING.TITLE');
    this.description = this.getTranslation('OVERLAY.LOADING.DESCRIPTION');
    this.image = '/assets/images/illustration/loading.svg';
  }
}
