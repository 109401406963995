import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {ISubscriptionService, SUBSCRIPTION} from '@application/helper/subscription/subscription.interface';
import {ThemeConstantService} from '../../../shared/services/theme-constant.service';

@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html'
})
export class CommonLayoutComponent implements OnInit {
  public isFolded: boolean;
  public isSideNavDark: boolean;
  public isExpand: boolean;
  public selectedHeaderColor: string;
  private themeService: ThemeConstantService;
  private readonly _subscriptionService: ISubscriptionService;

  public constructor(themeService: ThemeConstantService, @Inject(SUBSCRIPTION) subscriptionService: ISubscriptionService) {
    this.themeService = themeService;
    this._subscriptionService = subscriptionService;
  }

  @HostListener('window:resize')
  public onResize(): void {
    if (window.innerWidth < 1024) {
      this.themeService.toggleFold(true);
    } else {
      this.themeService.toggleFold(false);
    }
  }

  public ngOnInit(): void {
    this.onResize();
    this.themeService.isMenuFoldedChanges.subscribe((isFolded: boolean) => (this.isFolded = isFolded));
    this.themeService.isSideNavDarkChanges.subscribe((isDark: boolean) => (this.isSideNavDark = isDark));
    this.themeService.selectedHeaderColor.subscribe((color: string) => (this.selectedHeaderColor = color));
    this.themeService.isExpandChanges.subscribe((isExpand: boolean) => (this.isExpand = isExpand));
  }
}
