import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnhancementControlService {
  private isPaginationEnabled$ = new BehaviorSubject<boolean>(false);

  get isPaginationEnabled(): boolean {
    return this.isPaginationEnabled$.value;
  }

  set isPaginationEnabled(value: boolean) {
    this.isPaginationEnabled$.next(value);
  }
}
