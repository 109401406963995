import {find, isEqual, isFunction, isNaN, isNumber, map, reject} from 'lodash-es';

export class EnumUtils {
  public static getEnumNames<T>(enumObject: T): string[] {
    return Object.keys(enumObject).filter((value: string) => isNaN(Number(value)) && !isFunction(enumObject[value]));
  }

  public static getEnumValues<T extends number | string, E>(enumObject: E): T[] {
    return map(EnumUtils.getEnumNames(enumObject), (name: string) => enumObject[name]);
  }

  public static getKeyFromValue<E>(enumObject: E, value: string | number): string {
    return find(
      reject(Object.keys(enumObject), (key: string) => typeof enumObject[key] !== 'string' && !isNumber(enumObject[key])),
      (key: any) => isEqual(enumObject[key], value)
    );
  }
}
