import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NOTIFICATION} from '@infrastructure/http/notification/http-notification.interface';
import {HttpNotificationService} from '@infrastructure/http/notification/http-notification.service';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzInputModule} from 'ng-zorro-antd/input';
import {NzListModule} from 'ng-zorro-antd/list';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzSwitchModule} from 'ng-zorro-antd/switch';
import {ThemeConstantService} from '../services/theme-constant.service';
import {SharedModule} from '../shared.module';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {QuickViewComponent} from './quick-view/quick-view.component';
import {SearchComponent} from './search/search.component';
import {NavigationComponent} from './side-nav/navigation.component';

const antdModule = [NzAvatarModule, NzBadgeModule, NzRadioModule, NzDropDownModule, NzListModule, NzDrawerModule, NzDividerModule, NzSwitchModule, NzInputModule, NzButtonModule];

@NgModule({
  exports: [CommonModule, HeaderComponent, SearchComponent, QuickViewComponent, NavigationComponent, FooterComponent],
  imports: [RouterModule, CommonModule, SharedModule, ...antdModule],
  declarations: [HeaderComponent, SearchComponent, QuickViewComponent, NavigationComponent, FooterComponent],
  providers: [ThemeConstantService, {provide: NOTIFICATION, useClass: HttpNotificationService}]
})
export class TemplateModule {}
