import {L10nIntlService} from 'angular-l10n';
import {isEmpty, isEqual, isNil, isNumber} from 'lodash-es';

export class LocaleUtils {
  public static getDecimalSeparator(l10nIntlService: L10nIntlService): string {
    return this.formatNumber(1.1, l10nIntlService).charAt(1);
  }

  public static formatNumber(value: number, l10nIntlService: L10nIntlService, maximumFractionDigits = 13): string {
    const decimalPlacesLength = LocaleUtils.getDecimalCasesNumber(value);
    const nonDecimalValue = Math.trunc(value);
    const decimalValue = Math.abs(value - nonDecimalValue);
    const decimalFormat = l10nIntlService.formatNumber(decimalValue.toFixed(decimalPlacesLength), {digits: `1.0-${maximumFractionDigits}`});

    return !isNil(value) ? `${nonDecimalValue}${decimalFormat.substr(1)}` : null;
  }

  public static parseNumber(s: string | number, l10nIntlService: L10nIntlService): number {
    return isNumber(s) ? s : isEmpty(s) ? null : Number(s.replace(this.getDecimalSeparator(l10nIntlService), '.'));
  }

  private static getDecimalCasesNumber(value: number): number {
    let result = 0;
    if (!isEqual(Math.floor(value), value) && !isNil(value)) {
      result = value.toString().split('.')[1].length || 0;
    }
    return result;
  }
}
