export class MessageToast {
  private _isVisible: boolean;
  private _toastType: string;
  private _message: string;
  private _createdDate: Date;

  public constructor(isVisible: boolean, toastType?: string, message?: string) {
    this._isVisible = isVisible;
    this._toastType = toastType;
    this._message = message;
    this._createdDate = new Date();
  }

  public get isVisible(): boolean {
    return this._isVisible;
  }

  public set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
  }

  public get toastType(): string {
    return this._toastType;
  }

  public set toastType(toastType: string) {
    this._toastType = toastType;
  }

  public get message(): string {
    return this._message;
  }

  public set message(message: string) {
    this._message = message;
  }
}
