import {isNil} from 'lodash-es';

export class IdName {
  private _id: number;
  private _name: string;

  public constructor(id: number, name: string = null) {
    this._id = id;
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public static fromJSON(data: any): IdName {
    return !isNil(data) ? new IdName(data.id, data.name) : null;
  }

  public toJSON(): JSON {
    return {
      id: this._id,
      name: this._name
    } as any as JSON;
  }
}
