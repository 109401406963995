import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@application/translation/translate.service';
import {isNil} from 'lodash-es';

@Pipe({
  name: 'dataAvailability'
})
export class DataAvailabilityPipe implements PipeTransform {
  private readonly _translateService: TranslateService;

  public constructor(translate: TranslateService) {
    this._translateService = translate;
  }

  public transform(value: any): string {
    return isNil(value) ? this._translateService.instant('GENERAL.NOT_AVAILABLE') : value;
  }
}
