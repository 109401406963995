<section fxFlex fxLayout="column" fxLayoutGap="16px">
  <div class="dialog-header" fxLayout="column" fxLayoutAlign="center center">
    <h2 fxFlex>{{ dialogData.titleText | translate }}</h2>
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>
  <article class="dialog-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
    <img src="/assets/images/illustration/{{ dialogData.illustrationName }}.svg" alt="illustration" />
    <p *ngIf="hasExtraInformationText()" [innerHTML]="dialogData.extraInformationText"></p>
    <p [innerHTML]="dialogData.messageText | translate"></p>
    <ng-container #container></ng-container>
  </article>
  <mat-dialog-actions fxLayout="row" fxLayoutGap="22px" fxLayoutAlign="center">
    <button *ngIf="hasCancelButton()" mat-stroked-button (click)="cancel()">
      {{ dialogData.cancelButtonText | translate }}
    </button>
    <button *ngIf="hasReportButton()" mat-flat-button (click)="report()">
      {{ 'GENERAL.REPORT' | translate }}
    </button>
    <button *ngIf="hasDeleteButton()" mat-flat-button (click)="delete()">
      {{ 'GENERAL.ACTIONS.CONFIRM' | translate }}
    </button>
    <button *ngIf="hasConfirmButton()" mat-flat-button (click)="confirm()">
      {{ getConfirmButtonText() | translate }}
    </button>
  </mat-dialog-actions>
</section>
