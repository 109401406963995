<section fxLayout="column">
  <span class="module-category-title"> {{ moduleCategoryTitle }} </span>
  <div fxLayout="row" class="title">
    <h2>{{ moduleTitle }}</h2>
    <div *ngIf="showAddButton" fxFlex fxLayoutAlign="end center" fxLayoutGap="8px">
      <ng-content select="[import-button]"></ng-content>
      <ng-content select="[user-phonelog-button]"></ng-content>
      <button *appHasPermission="addPermission" class="vdw-button vdw-primary-button add-button" fxLayout="row" fxLayoutAlign="center center" (click)="navigateToAddPage()">
        <mat-icon svgIcon="{{ buttonIcon ?? 'plus-2.5px' }}"></mat-icon>
        {{ buttonTitle }}
      </button>
    </div>

    <div *ngIf="showCustomizedButton" fxFlex fxLayoutAlign="end center" fxLayoutGap="8px">
      <ng-content select="[customized-button]"></ng-content>
    </div>
  </div>
  <mat-sidenav-container class="grid-width vdw-card overview" [hasBackdrop]="false" fxFlex="0 1 100%">
    <mat-sidenav *ngIf="showSideNav && !isShowDialog" #sidenav mode="over" position="end" [fixedInViewport]="false" [autoFocus]="false">
      <mat-card *ngIf="canShowSidebar()" fxFlex>
        <mat-card-header fxLayout="row" fxLayoutAlign="space-between center">
          <mat-card-title fxLayout="row" fxLayoutGap="8px">
            <mat-icon class="card-header-icon" [svgIcon]="detailIcon"></mat-icon>
            <h2>{{ detailTitle }}</h2>
          </mat-card-title>
          <button mat-icon-button class="close-button" (click)="hideSidebar()">
            <mat-icon svgIcon="cross"></mat-icon>
          </button>
        </mat-card-header>
        <mat-card-content class="form-scrollable-content height-max" fxLayout="column" fxLayoutGap="16px">
          <ng-content></ng-content>
          <span class="last-modified" fxLayoutAlign="end end">
            {{ 'GENERAL.MODIFIED_AT_OBJECT' | translate: {date: selectedItem?.modifiedAt || selectedItem?.createdAt | date} }}
          </span>
        </mat-card-content>
        <mat-card-footer fxLayoutAlign="center center">
          <button *appHasPermission="viewPermission" class="vdw-button vdw-primary-button" (click)="navigateToDetailsPage()">{{ 'GENERAL.ACTIONS.MORE' | translate }}</button>
        </mat-card-footer>
      </mat-card>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" fxFlex="0 1 100%">
      <div [ngClass]="showSearchFilter ? 'overview-content' : 'hide-nav'" fxFlex fxLayout="column">
        <div class="height-max" fxLayout="column" fxLayoutGap="16px">
          <div *ngIf="showSearchFilter" fxLayout="row" fxLayoutGap="16px">
            <app-filter [isAlphanumericValidation]="isAlphanumericValidation" #searchFilter fxFlex="20" appAutoFocus (filteredTextChanged)="filter($event)"></app-filter>
            <app-advanced-search
              *ngIf="canShowAdvancedSearch"
              style="width: 100%"
              [advancedSearchFilters]="advancedSearchFilters"
              [advancedSearchOptionFilters]="advancedSearchOptionFilters"
              (searchFiltersChanged)="searchFiltersChanged()"
            ></app-advanced-search>
          </div>
          <ng-content select="[extra-actions]"></ng-content>
          <article class="grid-layout" fxLayout="column" fxFlex>
            <ag-grid-angular [gridOptions]="gridOptions" [rowData]="listOfData" [pagination]="true"></ag-grid-angular>
          </article>
          <article>
            <app-pagination [gridOptions]="gridOptions" [paginationPageSize]="paginationPageSize" [pageSizeOptions]="pageSizeOptions"></app-pagination>
          </article>
        </div>
      </div>
      <div *ngIf="canShowFooter" class="overview-footer" fxLayout="row" fxLayoutAlign="end center">
        <ng-content select="[content-footer]"></ng-content>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</section>
