<section fxFlex fxLayout="column">
  <div class="dialog-header" fxLayout="column" fxLayoutAlign="center center">
    <h2 fxFlex>{{ 'GENERAL.ACTIONS.DELETE' | translate }}{{ 'GENERAL.DATA' | translate }}</h2>
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>
  <article class="dialog-content">
    <div fxLayout="column" fxLayoutAlign="center center">
      <img src="/assets/images/illustration/confirm-delete.svg" alt="illustration" />
      <p>
        {{ 'GENERAL.ACTIONS.CONFIRM_DELETE' | translate }} <br />
        {{ 'GENERAL.UNABLE_TO_RESTORE' | translate }}
      </p>
    </div>
  </article>
  <mat-dialog-actions fxLayout="row" fxLayoutGap="22px" fxLayoutAlign="center">
    <button mat-stroked-button (click)="cancel()">
      {{ 'GENERAL.ACTIONS.CANCEL' | translate }}
    </button>
    <button mat-flat-button (click)="confirm()">
      {{ 'GENERAL.ACTIONS.DELETE' | translate }}
    </button>
  </mat-dialog-actions>
</section>
