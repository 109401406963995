import {RouteBranch} from '../route-branch';
import {RouteLeaf} from '../route-leaf';

export class UserRoute extends RouteBranch {
  private _addRoute: RouteLeaf;
  private _editRoute: RouteLeaf;
  private _detailsRoute: RouteLeaf;
  private _phoneLog: RouteLeaf;

  public get addRoute(): RouteLeaf {
    return this._addRoute;
  }

  public set addRoute(value: RouteLeaf) {
    this.replace(this._addRoute, value);
    this._addRoute = value;
  }

  public get editRoute(): RouteLeaf {
    return this._editRoute;
  }

  public set editRoute(value: RouteLeaf) {
    this.replace(this._editRoute, value);
    this._editRoute = value;
  }

  public get detailsRoute(): RouteLeaf {
    return this._detailsRoute;
  }

  public set detailsRoute(value: RouteLeaf) {
    this.replace(this._detailsRoute, value);
    this._detailsRoute = value;
  }

  public get phoneLogRoute(): RouteLeaf {
    return this._phoneLog;
  }

  public set phoneLogRoute(value: RouteLeaf) {
    this.replace(this._phoneLog, value);
    this._phoneLog = value;
  }
}
