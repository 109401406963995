import {AfterViewInit, Directive, ElementRef, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';
import {isNil} from 'lodash-es';

@Directive({
  selector: '[appFieldBlankInput]'
})
export class FieldBlankInputDirective implements AfterViewInit {
  @Input() frontElementId: string;
  @Input() endElementId: string;
  private readonly _control: NgControl;
  private _el: ElementRef;
  private _parent: any;
  private _defaultValue: string;
  private _frontElement: any;
  private _endElement: any;
  private _isSetValue = false;

  constructor(el: ElementRef, control: NgControl) {
    this._el = el;
    this._control = control;
  }

  @HostListener('click') onClick(): void {
    this._el.nativeElement.style.backgroundColor = 'transparent';
    if (this.getValue() === '') {
      this._el.nativeElement.selectionStart = 0;
      this._el.nativeElement.selectionEnd = 0;
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: any): void {
    const value = this.getValue();
    if (!this._isSetValue) {
      if (value !== '') {
        this._el.nativeElement.value =
          (!isNil(this._frontElement) && !value.includes(this._frontElement.innerHTML) ? this._frontElement.innerHTML : '') +
          value +
          (!isNil(this._endElement) && !value.includes(this._endElement.innerHTML) ? this._endElement.innerHTML : '');

        if (!isNil(this._frontElement)) {
          this._frontElement.remove();
        }

        if (!isNil(this._endElement)) {
          this._endElement.remove();
        }

        this._el.nativeElement.style.textDecoration = 'none';
        this._el.nativeElement.style.background = 'transparent';
        this._el.nativeElement.style.maxWidth = 'none';

        this._control.control.setValue(this._el.nativeElement.value);
        this._isSetValue = true;
      }
    }
  }

  public ngAfterViewInit(): void {
    this._el.nativeElement.style.background = 'repeating-linear-gradient(#13296D 0 1ch, transparent 1ch calc(1ch + 5px)) bottom / 100% 1px content-box no-repeat';
    this._el.nativeElement.style.backgroundColor = 'rgba(202, 214, 255, 1)';
    this._defaultValue = this._el.nativeElement.value;
    this._parent = this._el.nativeElement.parentNode;
    this._frontElement = this._parent.querySelector(`#${this.frontElementId}`);
    this._endElement = this._parent.querySelector(`#${this.endElementId}`);
  }

  private getValue(): string {
    return this._el.nativeElement.value.replaceAll(new RegExp('[_]', 'g'), '');
  }
}
