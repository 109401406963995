export class CheckAccountResponse {
  private _valid: boolean;
  private _forceChangeRequired: boolean;

  public constructor(valid: boolean, forceChangeRequired: boolean) {
    this._valid = valid;
    this._forceChangeRequired = forceChangeRequired;
  }

  public get valid(): boolean {
    return this._valid;
  }

  public set valid(valid: boolean) {
    this._valid = valid;
  }

  public get forceChangeRequired(): boolean {
    return this._forceChangeRequired;
  }

  public set forceChangeRequired(forceChangeRequired: boolean) {
    this._forceChangeRequired = forceChangeRequired;
  }

  public static fromJSON(response: any): CheckAccountResponse {
    return new CheckAccountResponse(response.valid, response.forceChangeRequired);
  }
}
