import {TranslateService} from '@application/translation/translate.service';
import {get, isEmpty, isEqual, isNil, join, lowerCase, map, size, truncate} from 'lodash-es';

export class StringUtils {
  public static slugifyString(text: string): string {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/&/g, '-and-')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-');
  }

  public static stringComparator(valueA: string, valueB: string): number {
    if (isNil(valueA) && isNil(valueB)) {
      return 0;
    }

    if (isNil(valueA)) {
      return -1;
    }
    if (isNil(valueB)) {
      return 1;
    }

    const upperValueA = valueA.toUpperCase();
    const upperValueB = valueB.toUpperCase();
    return isEqual(upperValueA, upperValueB) ? 0 : upperValueA > upperValueB ? 1 : -1;
  }

  public static createStringFromColorsOrYarnTypes(colorsOrYarnTypes: any[]): string {
    return join(map(colorsOrYarnTypes, 'name'), ', ');
  }

  public static createNameWithVersion(name: string, version: number): string {
    let result = '';

    if (!isEmpty(name)) {
      result = name;
      if (!isNil(version)) {
        result += ` (v${version})`;
      }
    }

    return result;
  }

  public static truncateString(text: string, length: number): string {
    return truncate(text, {length});
  }

  public static hasStringExceededGivenMaxSize(text: string, maxSize: number): boolean {
    return size(text) > maxSize;
  }

  public static isEqualIgnoreCase(text1: string, text2: string): boolean {
    return isEqual(text1.toLocaleLowerCase(), text2.toLocaleLowerCase());
  }

  public static getPropertyValueOrGeneralPlaceholderIfEmpty(objectToInspect: any, property: string, translate: TranslateService): string {
    const value = get(objectToInspect, property);
    return isEmpty(value) ? translate.instant('GENERAL.NO_DATA', {object: lowerCase(translate.instant('GENERAL.NAME'))}) : value;
  }
}
