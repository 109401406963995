import {Country} from '@domain/country-enum';
import {IdName} from '@infrastructure/id-name';

export class DefaultAddress {
  public readonly country: IdName;
  public readonly street: string;
  public readonly postalCode: string;
  public readonly province: IdName;
  public readonly city: IdName;
  public readonly district: IdName;

  constructor(country: IdName, street: string, postalCode: string, province: IdName, city: IdName, district: IdName) {
    this.country = country;
    this.street = street;
    this.postalCode = postalCode;
    this.province = province;
    this.city = city;
    this.district = district;
  }

  public get formattedAddress(): string {
    let address = [];
    if (this.country.id === Country.CHINA) {
      address = [this.country.name, this.province.name, this.city?.name, this.district?.name, this.street, this.postalCode];
    } else {
      address = [this.street, this.postalCode, this.country.name];
    }

    return address.filter((item) => item).join(', ');
  }
}
