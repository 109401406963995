<div fxLayout="column" fxFlex fxLayoutGap="16px">
  <div class="mat-dialog-header" fxLayout="column" fxLayoutAlign="center center">
    <h2 mat-dialog-title>{{ title }}</h2>
    <button mat-icon-button mat-dialog-close class="close-button">
      <mat-icon svgIcon="cross"></mat-icon>
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon [hidden]="!confirmationPreview" [fxHide]="page === 0" class="icon-arrow pointer" matSuffix svgIcon="chevron-left" style="height: 40px" (click)="previousPage()"></mat-icon>
    <img *ngIf="selectedFile.format === 'jpg'" [ngClass]="confirmationPreview ? 'preview-container' : 'preview-file'" src="{{ selectedFile.review }}" />
    <pdf-viewer
      *ngIf="selectedFile.format === 'pdf'"
      [src]="selectedFile.review"
      [ngClass]="confirmationPreview ? 'preview-container' : 'preview-file'"
      [show-all]="false"
      [original-size]="false"
    ></pdf-viewer>
    <ngx-doc-viewer
      *ngIf="selectedFile.format === 'docx' || selectedFile.format === 'doc' || selectedFile.format === 'xlsx' || selectedFile.format === 'xlx'"
      [url]="selectedFile.review"
      viewer="office"
      [ngClass]="confirmationPreview ? 'preview-container' : 'preview-file'"
    ></ngx-doc-viewer>
    <mat-icon [hidden]="!confirmationPreview" [fxHide]="page === totalPages" class="icon-arrow pointer" matSuffix svgIcon="chevron-right" style="height: 40px" (click)="nextPage()"></mat-icon>
  </div>
  <div [hidden]="confirmationPreview" style="height: 40px; padding-top: 16px" fxLayout="row">
    <mat-icon [fxHide]="page === 0" class="icon-arrow pointer" matSuffix svgIcon="chevron-left" (click)="previousPage()"></mat-icon>
    <mat-icon class="icon" matSuffix svgIcon="file-{{ selectedFile.format }}"></mat-icon>
    <mat-label style="margin-left: 4px" class="label-file">{{ selectedFile.displayName }}</mat-label>
    <mat-icon style="margin-left: auto; margin-right: 0" class="icon pointer" matSuffix svgIcon="download" (click)="downloadFile()"></mat-icon>
    <mat-icon [fxHide]="page === totalPages" class="icon-arrow pointer" matSuffix svgIcon="chevron-right" (click)="nextPage()"></mat-icon>
  </div>
  <div [hidden]="!confirmationPreview" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
    <button class="cancel-btn" mat-flat-button (click)="cancel()">{{ 'GENERAL.ACTIONS.CANCEL' | translate }}</button>
    <app-progress-button mat-flat-button label="GENERAL.ACTIONS.CONFIRM" [disabled]="processing" [inProgress]="processing" (progressClick)="confirm()"></app-progress-button>
  </div>
</div>
