export enum PriceVerificationStatus {
  PENDING_INPUT = 1,
  PENDING_SEND_TO_BPM,
  PENDING_BPM_APPROVAL,
  APPROVED,
  REVERIFY,
  REJECTED
}

export const PRICE_VERIFICATION_VERIFIED_STATUSES = [PriceVerificationStatus.APPROVED, PriceVerificationStatus.REJECTED, PriceVerificationStatus.REVERIFY];
export const PRICE_VERIFICATION_EDITABLE_STATUSES = [PriceVerificationStatus.PENDING_INPUT, PriceVerificationStatus.REVERIFY];
export const PRICE_VERIFICATION_SHOW_REMARK_STATUSES = [PriceVerificationStatus.REJECTED, PriceVerificationStatus.REVERIFY];
