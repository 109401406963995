import {IModulePermission} from '@application/directives/has-permission.directive';
import {Module} from '@domain/organization/role/module.enum';
import {Permission} from '@domain/organization/role/permission.enum';
import {filter, includes, isEmpty, isEqual, keys, map, startsWith} from 'lodash-es';

export abstract class RouteNode {
  private readonly _path: string;
  private readonly _module: Module;
  private readonly _requiredPermission: Permission;
  private readonly _isMobileFriendly: boolean;
  private readonly _isMobileOnly: boolean;
  private _parent: RouteNode;

  protected constructor(path: string, module: Module, requiredPermission: Permission, isMobileFriendly: boolean = false, isMobileOnly: boolean = false) {
    this._path = path;
    this._module = module;
    this._requiredPermission = requiredPermission;
    this._isMobileFriendly = isMobileFriendly;
    this._isMobileOnly = isMobileOnly;
  }

  public get parent(): RouteNode {
    return this._parent;
  }

  public set parent(parent: RouteNode) {
    this._parent = parent;
  }

  public get path(): string {
    return this._path;
  }

  public get module(): Module {
    return this._module;
  }

  public get requiredPermission(): Permission {
    return this._requiredPermission;
  }

  public get modulePermission(): IModulePermission {
    return {module: this._module, requiredPermission: this._requiredPermission};
  }

  public get isMobileFriendly(): boolean {
    return this._isMobileFriendly;
  }

  public get isMobileOnly(): boolean {
    return this._isMobileOnly;
  }

  public get hasParameter(): boolean {
    return includes(this._path, '/:');
  }

  public get amountOfRouteParameters(): number {
    return this.hasParameter ? this._path.match(new RegExp('/:', 'g')).length : 0;
  }

  public get absolutePath(): string {
    return RouteNode.getAbsolutePath(this);
  }

  private static getAbsolutePath(node: RouteNode, accumulatedPath: string = ''): string {
    let result = node.path + accumulatedPath;

    if (!isEmpty(node.path)) {
      result = '/' + result;
    }

    if (node.parent) {
      result = RouteNode.getAbsolutePath(node.parent, result);
    }

    return result;
  }

  public matchesPath(path: string): boolean {
    return this.hasParameter ? startsWith(this.path, path) : isEqual(this.path, path);
  }

  public getRoutes(): RouteNode[] {
    return filter(
      map(keys(this), (key: string) => this[key]),
      (property: RouteNode[] | RouteNode | Record<string, unknown>) => {
        return isEqual(typeof property, 'object') && !(property instanceof Array);
      }
    );
  }

  public abstract isKnownPath(pathSegments: string[]): boolean;
}
