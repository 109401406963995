<button
  mat-flat-button
  [tabIndex]="tabIndex"
  *ngIf="isRaisedButton()"
  color="primary"
  type="button"
  disabled
  [disabled]="isDisabled()"
  [class.saving]="isInProgress()"
  [style.width]="getButtonWidth()"
  (click)="startProgress()"
  [ngClass]="{
    'ng-invalid': invalid,
    invalid: invalid
  }"
>
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>

<button
  mat-stroked-button
  [tabIndex]="tabIndex"
  *ngIf="!isRaisedButton()"
  type="button"
  [disabled]="isDisabled()"
  [class.saving]="isInProgress()"
  [style.width]="getButtonWidth()"
  (click)="startProgress()"
  [ngClass]="{
    'ng-invalid': invalid,
    invalid: invalid
  }"
>
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>

<ng-template #btnContent>
  <mat-spinner diameter="22" *ngIf="isInProgress()" mode="indeterminate"></mat-spinner>
  <span class="button-text" [class.hide]="!showLabel()">
    {{ label | translate: labelReplaceableValues }}
  </span>
</ng-template>
