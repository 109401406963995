<div
  class="common-layout {{ 'is-' + selectedHeaderColor }}"
  [ngClass]="{
    'is-folded': isFolded,
    'is-side-nav-dark': isSideNavDark,
    'is-expand': isExpand
  }"
>
  <app-header></app-header>
  <app-navigation></app-navigation>
  <div class="page-container">
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
