import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  public processing = false;
  protected unSubscribeOnViewDestroy: Subject<boolean> = new Subject<boolean>();

  public finalizeProcessing(): () => void {
    return (): boolean => (this.processing = false);
  }

  public ngOnDestroy(): void {
    this.unSubscribeOnViewDestroy.next(true);
    this.unSubscribeOnViewDestroy.complete();
  }
}
