import {RouteBranch} from './route-branch';
import {RouteLeaf} from './route-leaf';

export class AuthenticationRoute extends RouteBranch {
  private _login: RouteLeaf;
  private _forgotPassword: RouteLeaf;
  private _otpVerification: RouteLeaf;
  private _resetPassword: RouteLeaf;
  private _firstTimeLogin: RouteLeaf;
  private _logout: RouteLeaf;

  public get login(): RouteLeaf {
    return this._login;
  }

  public set login(value: RouteLeaf) {
    this.replace(this._login, value);
    this._login = value;
  }

  public get forgotPassword(): RouteLeaf {
    return this._forgotPassword;
  }

  public set forgotPassword(value: RouteLeaf) {
    this.replace(this._forgotPassword, value);
    this._forgotPassword = value;
  }

  public get otpVerification(): RouteLeaf {
    return this._otpVerification;
  }

  public set otpVerification(value: RouteLeaf) {
    this.replace(this._otpVerification, value);
    this._otpVerification = value;
  }

  public get resetPassword(): RouteLeaf {
    return this._resetPassword;
  }

  public set resetPassword(value: RouteLeaf) {
    this.replace(this._resetPassword, value);
    this._resetPassword = value;
  }

  public get firstTimeLogin(): RouteLeaf {
    return this._firstTimeLogin;
  }

  public set firstTimeLogin(value: RouteLeaf) {
    this.replace(this._firstTimeLogin, value);
    this._firstTimeLogin = value;
  }

  public get logout(): RouteLeaf {
    return this._logout;
  }

  public set logout(value: RouteLeaf) {
    this.replace(this._logout, value);
    this._logout = value;
  }
}
