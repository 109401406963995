<app-template-base showLogo="true">
  <mat-card-content>
    <mat-grid-list cols="5" rowHeight="fit">
      <mat-grid-tile [colspan]="3" [rowspan]="1">
        <div [className]="'status-code-illustration status-code-' + getStatusCode()"></div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="2" [rowspan]="1">
        <div class="status-code-container">
          <h1 class="status-code-title">{{ getStatusCode() }}</h1>
          <p class="status-code-description">{{ getDescription() }}</p>
          <button mat-flat-button class="sa-button" (click)="navigateBackTo()">{{ 'GENERAL.NAVIGATION.BACK' | translate }}</button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</app-template-base>
