import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '@presentation/base-component';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  templateUrl: './hyperlink-renderer.component.html'
})
export class HyperlinkComponent extends BaseComponent implements ICellRendererAngularComp {
  public showLink: boolean;
  public fieldText: string;

  private state: any;
  private id: number;
  private url: string;

  private readonly _router: Router;

  public constructor(router: Router) {
    super();
    this._router = router;
  }
  public agInit(params: ICellRendererParams): void {
    this.state = params.context.state;
    this.id = params.value;
    this.fieldText = params.context.fieldText;
    this.showLink = !params.data.fullWidth;
    this.url = params.context.url;
  }

  public refresh(params: any): boolean {
    return false;
  }

  navigateViewUrl(): void {
    this._router.navigateByUrl(this.url.replace(':id', String(this.id)), {
      state: this.state
    });
  }
}
