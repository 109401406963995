import {AfterViewChecked, Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppConfiguration} from '@application/configuration/application-config';
import {INavigationBarItem} from '@application/helper/navigation/navigation-bar-item';
import {NavigationBarItemUtils} from '@application/helper/navigation/navigation-bar-item-utils';
import {Subscription} from '@application/helper/subscription/subscription';
import {ISubscriptionService, SUBSCRIPTION} from '@application/helper/subscription/subscription.interface';
import {RouteUtils} from '@application/routing/route-utils';
import {TranslateService} from '@application/translation/translate.service';
import {BaseComponent} from '@presentation/base-component';
import {cloneDeep, isEmpty, isNil} from 'lodash-es';
import {ThemeConstantService} from '../../services/theme-constant.service';

declare let $: any; // JQuery

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent extends BaseComponent implements OnInit, AfterViewChecked {
  public menuItems: any[];
  public isCollapsed: boolean;
  public isSideNavDark: boolean;
  public isExpand: boolean;
  public isPreviewMode: boolean;

  private readonly _router: Router;
  private readonly _subscriptionService: ISubscriptionService;
  private _themeService: ThemeConstantService;
  private _translateService: TranslateService;

  public constructor(@Inject(SUBSCRIPTION) subscriptionService: ISubscriptionService, router: Router, appConfig: AppConfiguration, themeService: ThemeConstantService, translate: TranslateService) {
    super();

    this._subscriptionService = subscriptionService;
    this._router = router;
    this._themeService = themeService;
    this._translateService = translate;
    this.isPreviewMode = appConfig.isPreviewMode;
  }

  public ngOnInit(): void {
    this.setNavigationBarItems(this._subscriptionService.getCurrentSubscription(), false);

    this._themeService.isMenuFoldedChanges.subscribe((isFolded: boolean) => (this.isCollapsed = isFolded));
    this._themeService.isExpandChanges.subscribe((isExpand: boolean) => (this.isExpand = isExpand));
    this._themeService.isSideNavDarkChanges.subscribe((isDark: boolean) => (this.isSideNavDark = isDark));
  }

  public ngAfterViewChecked(): void {
    const selectedItem = $('.side-nav .nav-menu .ant-menu-item-selected')[0];

    if (!isNil(selectedItem)) {
      const openClassName = 'ant-menu-submenu-open';
      const selectedMenuItem = $(selectedItem).hasClass('nav-menu-item') ? selectedItem : $(selectedItem).parents('.nav-menu-item')[0];

      // collapse others menu items
      const allOpenedSiblings = $(selectedMenuItem).siblings(`.${openClassName}`);
      $(allOpenedSiblings).removeClass(openClassName);
      $(allOpenedSiblings).children('.nav-submenu').slideUp(100);

      if (!$(selectedMenuItem).hasClass(openClassName)) {
        $(selectedMenuItem)
          .children('.nav-submenu')
          .slideDown(200, function () {
            $(selectedMenuItem).addClass(openClassName);
          });
      }
    }
  }

  public selectMenu(id: string): void {
    const selectedMenuItem = this.menuItems.find((menuItem: any) => menuItem.id === id);

    if (!isEmpty(selectedMenuItem.subItems)) {
      this._router.navigateByUrl(selectedMenuItem.subItems[0].path);
    }
  }

  public closeMobileMenu(): void {
    if (window.innerWidth < 992) {
      this.isCollapsed = false;
      this.isExpand = !this.isExpand;
      this._themeService.toggleExpand(this.isExpand);
      this._themeService.toggleFold(this.isCollapsed);
    }
  }

  public toggleFold(): void {
    this.isCollapsed = !this.isCollapsed;
    this._themeService.toggleFold(this.isCollapsed);
  }

  public getTitle(title: string, withManagementTitle: boolean): string {
    let result = `${this._translateService.instant(title)}`;

    if (withManagementTitle) {
      result = `${this._translateService.instant('GENERAL.OBJECT_WITH_MANAGEMENT', {object: this._translateService.instant(title)})}`;
    }

    return result;
  }

  private setNavigationBarItems(subscription: Subscription, isMobile: boolean): void {
    let navigationBarItems = cloneDeep(RouteUtils.navigationBarRouteLinkItems) as INavigationBarItem[];

    // temporary by pass for stag, will remove once BA tested
    // if (!this.isPreviewMode) {
    //   navigationBarItems = navigationBarItems.filter((item: NavigationBarRouteLinkItem) => {
    //     let isDisplay = !ModuleUtils.isPreviewOnly(item.route?.module);

    //     if (isDisplay && !isEmpty(item.subItems)) {
    //       item.subItems = item.subItems.filter((subItem: NavigationBarRouteLinkItem) => {
    //         return !ModuleUtils.isPreviewOnly(subItem.route?.module);
    //       });
    //       isDisplay = !isEmpty(item.subItems);
    //     }

    //     return isDisplay;
    //   });
    // } else {
    navigationBarItems = NavigationBarItemUtils.filterNavigationBarItemsByPermissionAndMobileAvailability(navigationBarItems, subscription, isMobile, this.isPreviewMode);
    // }

    this.menuItems = NavigationBarItemUtils.sortNavigationBarItems(navigationBarItems);
  }
}
