import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatCalendarHeader} from '@angular/material/datepicker';
import {isEqual} from 'lodash-es';
import moment from 'moment';

@Component({
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerHeaderComponent extends MatCalendarHeader<Date> {
  public get periodButtonText(): string {
    let result = super.periodButtonText;

    if (isEqual(this.calendar.currentView, 'month')) {
      result = moment(this.calendar.activeDate).format('MMMM YYYY');
    }

    return result;
  }
}
