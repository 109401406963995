import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {WINDOW} from '@application/window/window.service';
import {DimensionsInPx} from '@domain/dimensions-in-px';
import {PositionOfDialog} from '@domain/position-of-dialog';
import {BaseComponent} from '@presentation/base-component';
import {isEqual, max, min} from 'lodash-es';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-reposition-dialog',
  templateUrl: './reposition-dialog.component.html',
  styleUrls: ['./reposition-dialog.component.scss']
})
export class RepositionDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  private static readonly arrowMargin = 16;
  private static readonly arrowPadding = 1.5;
  private static readonly defaultLeftArrowOffset = 18;
  private static readonly dialogMargin = 16;
  @ViewChild('rootElement', {static: true}) private rootElement: ElementRef;
  @ViewChild('container', {read: ViewContainerRef, static: true}) private container: ViewContainerRef;
  public arrowPositionTop: number;
  public arrowPositionLeft: number;
  private readonly dialogRef: MatDialogRef<RepositionDialogComponent>;
  private readonly window: Window;
  private readonly component: any;
  private readonly sourceElement: Element;
  private positionOfDialog: PositionOfDialog = PositionOfDialog.BOTTOM;
  private readonly windowResizeSubject = new Subject();
  private windowDimensions: DimensionsInPx;
  private hasResizedDialog: boolean;

  public constructor(@Inject(MAT_DIALOG_DATA) data: any, dialogRef: MatDialogRef<RepositionDialogComponent>, @Inject(WINDOW) window: Window) {
    super();
    this.component = data.component;
    this.sourceElement = data.sourceElement;
    this.dialogRef = dialogRef;
    this.window = window;
  }

  private static isDialogOverflowingLeftEdgeOfWindow(dialogLeft: number): boolean {
    return dialogLeft < RepositionDialogComponent.getMinimumDialogPosition();
  }

  private static getMinimumDialogPosition(): number {
    return RepositionDialogComponent.dialogMargin;
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.windowDimensions = {widthInPx: this.window.innerWidth, heightInPx: this.window.innerHeight};
    this.windowResizeSubject.next();
  }

  public ngOnInit(): void {
    this.addComponent();
    this.initializeDialogPositioning();
  }

  public ngAfterViewInit(): void {
    this.windowResizeSubject.next();
  }

  public emitRepositionDialogEvent(): void {
    this.windowResizeSubject.next();
  }

  public repositionDialog(): void {
    let dialogWidth: number = parseInt(this.rootElement.nativeElement.clientWidth, 10);
    let dialogHeight: number = this.rootElement.nativeElement.clientHeight;
    const sourceRect: ClientRect | DOMRect = this.sourceElement.getBoundingClientRect();
    let dialogLeft: number = sourceRect.left;
    let dialogTop: number = sourceRect.bottom + RepositionDialogComponent.arrowMargin;

    if (this.isDialogOverflowing(dialogLeft, dialogTop, dialogWidth, dialogHeight)) {
      [dialogLeft, dialogWidth] = this.repositionDialogHorizontally(dialogLeft, dialogWidth);
      [dialogTop, dialogHeight] = this.repositionDialogVertically(dialogTop, dialogHeight);
    } else if (this.hasResizedDialog) {
      this.dialogRef.updateSize(`${dialogWidth}px`, `${dialogHeight}px`);
    }

    this.arrowPositionTop = -RepositionDialogComponent.arrowMargin - RepositionDialogComponent.arrowPadding;
    this.arrowPositionLeft = sourceRect.left + sourceRect.width / 2 - dialogLeft - RepositionDialogComponent.defaultLeftArrowOffset * 2;
    this.dialogRef.updatePosition({left: `${dialogLeft}px`, top: `${dialogTop}px`});
  }

  private addComponent(): void {
    this.container.createComponent(this.component);
  }

  private initializeDialogPositioning(): void {
    this.windowDimensions = {widthInPx: this.window.innerWidth, heightInPx: this.window.innerHeight};
    this.windowResizeSubject
      .asObservable()
      .pipe(debounceTime(500), takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.repositionDialog());
    this.repositionDialog();
  }

  private canShiftDialogHorizontally(): boolean {
    return isEqual(this.positionOfDialog, PositionOfDialog.TOP) || isEqual(this.positionOfDialog, PositionOfDialog.BOTTOM);
  }

  private canShiftDialogVertically(): boolean {
    return isEqual(this.positionOfDialog, PositionOfDialog.LEFT) || isEqual(this.positionOfDialog, PositionOfDialog.RIGHT);
  }

  private isDialogOverflowingRightEdgeOfWindow(dialogLeft: number, dialogWidth: number): boolean {
    return dialogLeft + dialogWidth > this.getMaximumDialogPositionRight();
  }

  private getMaximumDialogPositionRight(): number {
    return this.windowDimensions.widthInPx - RepositionDialogComponent.dialogMargin;
  }

  private isDialogOverflowingBottomEdgeOfWindow(dialogTop: number, dialogHeight: number): boolean {
    return dialogTop + dialogHeight > this.getMaximumDialogPositionBottom();
  }

  private getMaximumDialogPositionBottom(): number {
    return this.windowDimensions.heightInPx - RepositionDialogComponent.dialogMargin;
  }

  private isDialogOverflowingTopEdgeOfWindow(dialogTop: number): boolean {
    return dialogTop < RepositionDialogComponent.getMinimumDialogPosition();
  }

  private isDialogOverflowing(dialogLeft: number, dialogTop: number, dialogWidth: number, dialogHeight: number): boolean {
    return (
      RepositionDialogComponent.isDialogOverflowingLeftEdgeOfWindow(dialogLeft) ||
      this.isDialogOverflowingRightEdgeOfWindow(dialogLeft, dialogWidth) ||
      this.isDialogOverflowingTopEdgeOfWindow(dialogTop) ||
      this.isDialogOverflowingBottomEdgeOfWindow(dialogTop, dialogHeight)
    );
  }

  private repositionDialogHorizontally(dialogLeft: number, dialogWidth: number): [number, number] {
    if (RepositionDialogComponent.isDialogOverflowingLeftEdgeOfWindow(dialogLeft)) {
      if (this.canShiftDialogHorizontally()) {
        dialogWidth = min([dialogWidth, this.getMaximumDialogPositionRight() - dialogLeft]);
      } else {
        dialogWidth -= RepositionDialogComponent.getMinimumDialogPosition() - dialogLeft;
      }
      dialogLeft = RepositionDialogComponent.getMinimumDialogPosition();
      this.hasResizedDialog = true;
    } else if (this.isDialogOverflowingRightEdgeOfWindow(dialogLeft, dialogWidth)) {
      if (this.canShiftDialogHorizontally()) {
        dialogLeft = max([RepositionDialogComponent.getMinimumDialogPosition(), this.getMaximumDialogPositionRight() - dialogWidth]);
      }
      dialogWidth = this.getMaximumDialogPositionRight() - dialogLeft;
      this.hasResizedDialog = true;
    }

    return [dialogLeft, dialogWidth];
  }

  private repositionDialogVertically(dialogTop: number, dialogHeight: number): [number, number] {
    if (this.isDialogOverflowingTopEdgeOfWindow(dialogTop)) {
      if (this.canShiftDialogVertically()) {
        dialogHeight = min([dialogHeight, this.getMaximumDialogPositionBottom() - dialogTop]);
      } else {
        dialogHeight -= RepositionDialogComponent.getMinimumDialogPosition() - dialogTop;
      }

      dialogTop = RepositionDialogComponent.getMinimumDialogPosition();
      this.hasResizedDialog = true;
    } else if (this.isDialogOverflowingBottomEdgeOfWindow(dialogTop, dialogHeight)) {
      if (this.canShiftDialogVertically()) {
        dialogTop = max([RepositionDialogComponent.getMinimumDialogPosition(), this.getMaximumDialogPositionBottom() - dialogTop]);
      }
      dialogHeight = this.getMaximumDialogPositionBottom() - dialogTop;
      this.hasResizedDialog = true;
    }

    return [dialogTop, dialogHeight];
  }
}
