<div fxLayout="row" fxFlex fxLayoutAlign="start center" fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
    <span class="pagination-text">{{ 'GENERAL.AG_GRID.PAGINATION.ROWS_PER_PAGE' | translate }}</span>
    <mat-form-field appearance="fill" class="pagination-select" [floatLabel]="'auto'">
      <mat-select id="page-size" disableOptionCentering [value]="paginationPageSize" (selectionChange)="onPageSizeChanged($event)" panelClass="items-per-page-class">
        <mat-option *ngFor="let pageSize of pageSizeOptions" [value]="pageSize">
          {{ pageSize }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <span class="pagination-text">{{ paginationSummary }}</span>
  <div *ngIf="totalPages > 0" class="pagination" fxLayout="row" fxLayoutAlign="end center" fxFlex>
    <button class="page-navigation-button page-navigation-icon-button" (click)="goToFirstPage()" [disabled]="isFirstPage">
      <mat-icon svgIcon="first-page"></mat-icon>
    </button>
    <button class="page-navigation-button page-navigation-icon-button" (click)="goToPreviousPage()" [disabled]="isFirstPage">
      <mat-icon svgIcon="previous-page"></mat-icon>
    </button>
    <ng-container *ngFor="let page of pageNumberLabels">
      <button class="page-navigation-button" (click)="currentPage !== page && !(page === '...') ? setCurrentPage(page) : ''" [class.active]="currentPage === page" [class.ellipse]="page === '...'">
        {{ page }}
      </button>
    </ng-container>
    <button class="page-navigation-button page-navigation-icon-button" (click)="goToNextPage()" [disabled]="isLastPage">
      <mat-icon svgIcon="next-page"></mat-icon>
    </button>
    <button class="page-navigation-button page-navigation-icon-button" (click)="goToLastPage()" [disabled]="isLastPage">
      <mat-icon svgIcon="last-page"></mat-icon>
    </button>
  </div>
  <div *ngIf="totalPages > 0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px" class="currentPage">
    <span class="pagination-text">{{ 'GENERAL.AG_GRID.PAGINATION.GO_TO' | translate }}</span>
    <mat-form-field appearance="fill" [floatLabel]="'auto'">
      <input matInput type="number" [value]="currentPage" (change)="onPageChanged($event)" autocomplete="off" />
    </mat-form-field>
    <span class="pagination-text">{{ 'GENERAL.AG_GRID.PAGINATION.PAGE' | translate }}</span>
  </div>
</div>
