import {AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, QueryList, Renderer2, ViewChild, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTooltip} from '@angular/material/tooltip';
import {BaseComponent} from '@presentation/base-component';
import {ISelectFilterOption} from '@presentation/components/advanced-search-filters/advanced-search/select-filter-option';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {differenceWith, isEmpty, isEqual, isNil} from 'lodash-es';
import {AdvancedSearchFilter} from '../advanced-search/advanced-search-filter';

interface IFilterOption {
  value: string;
  text: string;
  display: boolean;
}

@Component({
  templateUrl: './advanced-search-filter-dialog.component.html',
  styleUrls: ['./advanced-search-filter-dialog.component.scss']
})
export class AdvancedSearchFilterDialogComponent extends BaseComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild('checkboxForm') checkboxFormComponent: ElementRef;
  @ViewChildren('filterOptionTooltip', {read: MatTooltip}) optionTooltipComponents: QueryList<MatTooltip>;

  public advancedSearchFilter: AdvancedSearchFilter;
  public advancedSearchForm: FormGroup;
  public filterOptions: IFilterOption[] = [];
  public allSelected = false;
  public addCurrentSelections = false;
  public noFilterFound = false;
  public filteredText = '';
  public selectAllLabel = 'GENERAL.ADVANCED_SEARCH.ALL';

  private readonly dialogRef: MatDialogRef<RepositionDialogComponent>;
  private readonly changeDetectorRef: ChangeDetectorRef;
  private readonly formBuilder: FormBuilder;
  private readonly renderer: Renderer2;
  private readonly maxOptionsDisplay = 5;

  public constructor(@Inject(MAT_DIALOG_DATA) data: any, dialogRef: MatDialogRef<RepositionDialogComponent>, changeDetectorRef: ChangeDetectorRef, formBuilder: FormBuilder, renderer: Renderer2) {
    super();
    this.dialogRef = dialogRef;
    this.changeDetectorRef = changeDetectorRef;
    this.formBuilder = formBuilder;
    this.renderer = renderer;
    this.advancedSearchFilter = data.advancedSearchFilter;
  }

  public ngOnInit(): void {
    const controlConfigs = {};
    this.advancedSearchFilter.propertyOptions.forEach((option: ISelectFilterOption) => {
      this.filterOptions.push({value: option.value, text: option.text, display: true});
      controlConfigs[option.value] = [this.advancedSearchFilter.selectedValues.includes(option.value)];
    });
    this.advancedSearchForm = this.formBuilder.group(controlConfigs);
    this.updateAllSelected();
  }

  public ngAfterViewInit(): void {
    this.optionTooltipComponents.forEach((el: any) => {
      const checkboxLabelElement = el._elementRef.nativeElement.querySelector('.mat-checkbox-label');
      const checkboxTextElement = el._elementRef.nativeElement.querySelector('.checkbox-text');
      el.disabled = checkboxTextElement.getBoundingClientRect().width <= checkboxLabelElement.getBoundingClientRect().width;
    });
    this.setOverflowContainer();
  }

  public ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public updateAllSelected(): void {
    this.allSelected = this.getFilteredOptionValues().every((x: any) => x, true);
  }

  public someSelected(): boolean {
    return this.getFilteredOptionValues().some((x: boolean) => x, true);
  }

  public setAll(selected: boolean): void {
    this.allSelected = selected;
    this.filterOptions.filter((option: IFilterOption) => option.display).forEach((option: IFilterOption) => this.advancedSearchForm.controls[option.value].setValue(this.allSelected));
  }

  public filter(event: string): void {
    this.filteredText = event;

    this.filterOptions.forEach((option: IFilterOption) => {
      if (isEmpty(this.filteredText)) {
        option.display = true;
        this.advancedSearchForm.controls[option.value].setValue(this.advancedSearchFilter.selectedValues.includes(option.value));
      } else {
        option.display = option.text.toLowerCase().indexOf(this.filteredText) !== -1;
        this.advancedSearchForm.controls[option.value].setValue(option.display);
      }
    });

    const filteredOptions = this.getFilteredOptionValues();
    this.allSelected = filteredOptions.every((x: any) => x, true);
    this.selectAllLabel = isEmpty(this.filteredText) ? 'GENERAL.ADVANCED_SEARCH.ALL' : 'GENERAL.ADVANCED_SEARCH.SELECT_ALL_FILTERED';
    this.addCurrentSelections = false;
    this.noFilterFound = filteredOptions.length === 0;

    this.setOverflowContainer();
  }

  public confirm(): void {
    const filteredOptions = this.filterOptions.filter((option: IFilterOption) => option.display);
    let selectedValues = [];

    if (this.addCurrentSelections) {
      selectedValues = differenceWith(
        this.advancedSearchFilter.selectedValues,
        filteredOptions.map((option: ISelectFilterOption) => option.value),
        isEqual
      );
    }

    filteredOptions.forEach((option: ISelectFilterOption) => {
      if (this.advancedSearchForm.controls[option.value].value) {
        selectedValues.push(option.value);
      }
    });

    this.dialogRef.close({selectedValues});
  }

  private setOverflowContainer(): void {
    if (!isNil(this.checkboxFormComponent)) {
      const targetElement = this.checkboxFormComponent.nativeElement;
      if (this.getFilteredOptionValues().length > this.maxOptionsDisplay) {
        this.renderer.setStyle(targetElement, 'height', `calc(28px * ${this.maxOptionsDisplay} - 4px + 8px)`);
        this.renderer.setStyle(targetElement, 'overflow-y', 'auto');
      } else {
        this.renderer.removeStyle(targetElement, 'height');
        this.renderer.removeStyle(targetElement, 'overflow-y');
      }
    }
  }

  private getFilteredOptionValues(): boolean[] {
    return this.filterOptions.filter((option: IFilterOption) => option.display).map((option: IFilterOption) => this.advancedSearchForm.controls[option.value].value);
  }
}
