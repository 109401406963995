import {Component, Inject, Input} from '@angular/core';
import {ResponsivenessViewMode, RESPONSIVENESS_VIEW_MODE} from '@application/responsiveness/responsiveness-view.mode';

@Component({
  selector: 'app-invalid-form-message',
  templateUrl: './invalid-form-message.component.html',
  styleUrls: ['./invalid-form-message.component.scss']
})
export class InvalidFormMessageComponent {
  @Input() public message = 'GENERAL.ERRORS.CHECK_INVALID_FIELDS';
  public responsivenessViewMode: ResponsivenessViewMode;

  public constructor(@Inject(RESPONSIVENESS_VIEW_MODE) responsivenessViewMode: ResponsivenessViewMode) {
    this.responsivenessViewMode = responsivenessViewMode;
  }

  public isNotMobile() {
    return !this.responsivenessViewMode.isPhone;
  }
}
