import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfiguration} from '@application/configuration/application-config';
import {Subscription} from '@application/helper/subscription/subscription';
import {GeneralResponse} from '@infrastructure/general.response';
import {LocalStorageService} from 'angular-2-local-storage';
import {jwtDecode, JwtPayload} from 'jwt-decode';
import {isNil, keys, map} from 'lodash-es';
import {Observable, Subject} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';
import {LocalizedName} from '../localized-name';
import {UserResponse} from '../organization/user/user.response';
import {AuthRequest} from './authentication.request';
import {AuthResponse} from './authentication.response';
import {ChangePasswordRequest} from './change-password.request';
import {CheckAccountResponse} from './check-account.response';
import {IAuthenticationService} from './http-authentication.interface';
import {RefreshTokenResponse} from './refresh-token.response';
import {ResetPasswordRequest} from './reset-password.request';
import {TokenResponse} from './token.response';

@Injectable({
  providedIn: 'root'
})
export class HttpAuthenticationService implements IAuthenticationService {
  protected _currentSubscriptionSubject: Subject<Subscription> = new Subject<Subscription>();
  private readonly _storageConstants = {
    SUBSCRIPTION: 'subscription',
    REDIRECT: null,
    NODE_ID: 'node-id',
    USER_CODE: 'user-code',
    USER_NAME: 'user-name'
  };
  private _httpClient: HttpClient;
  private _appConfig: AppConfiguration;
  private _localStorageService: LocalStorageService;
  private _authenticationParameters: string;

  public constructor(httpClient: HttpClient, appConfig: AppConfiguration, localStorageService: LocalStorageService) {
    this._httpClient = httpClient;
    this._appConfig = appConfig;
    this._localStorageService = localStorageService;
  }

  public getNodeId(): string {
    return this._localStorageService.get(this._storageConstants.NODE_ID);
  }

  public setNodeId(nodeId: number): Observable<void> {
    this._localStorageService.set(this._storageConstants.NODE_ID, nodeId);
    return new Observable();
  }

  public getUserCode(): string {
    return this._localStorageService.get(this._storageConstants.USER_CODE);
  }

  public setUserCode(userCode: string): Observable<void> {
    this._localStorageService.set(this._storageConstants.USER_CODE, userCode);
    return new Observable();
  }

  public getUserName(): any {
    return this._localStorageService.get(this._storageConstants.USER_NAME);
  }

  public setUserName(userName: LocalizedName[]): Observable<void> {
    this._localStorageService.set(this._storageConstants.USER_NAME, userName);
    return new Observable();
  }

  public setAuthenticationParameters(authenticationParameters: string): void {
    this._authenticationParameters = authenticationParameters;
  }

  public getRedirectAfterAuthentication(): string {
    return this._localStorageService.get(this._storageConstants.REDIRECT);
  }

  public setRedirectAfterAuthentication(url: string): void {
    if (isNil(url)) {
      this._localStorageService.remove(this._storageConstants.REDIRECT);
    } else {
      this._localStorageService.set(this._storageConstants.REDIRECT, url);
    }
  }

  public refreshToken(): Observable<RefreshTokenResponse> {
    return this._httpClient
      .get<RefreshTokenResponse>(`${this._appConfig.authenticationEndpoint()}account/refresh-token`, {})
      .pipe(rxjsMap((response: RefreshTokenResponse) => RefreshTokenResponse.fromJSON(response)));
  }

  public logout(): Observable<void> {
    return this._httpClient.post<void>(`${this._appConfig.authenticationEndpoint()}account/logout`, {});
  }

  public removeLocalStorage(): void {
    this._localStorageService.remove(...map(keys(this._storageConstants), (storageConstantKey: string) => this._storageConstants[storageConstantKey]));
  }

  public checkAccountValidity(phoneNumber: string): Observable<CheckAccountResponse> {
    return this._httpClient.post(`${this._appConfig.authenticationEndpoint()}account/check`, {phoneNumber}).pipe(rxjsMap((response: any) => CheckAccountResponse.fromJSON(response)));
  }

  public authenticate(request: AuthRequest): Observable<AuthResponse> {
    return this._httpClient.post(`${this._appConfig.authenticationEndpoint()}account/authenticate`, request.toJSON()).pipe(rxjsMap((response: any) => AuthResponse.fromJSON(response)));
  }

  public resetPassword(request: ResetPasswordRequest): Observable<boolean> {
    return this._httpClient.put(`${this._appConfig.authenticationEndpoint()}account/reset-password`, request.toJSON()).pipe(
      rxjsMap((response: any) => {
        const generalResponse = GeneralResponse.fromJSON(response);
        return generalResponse.success;
      })
    );
  }

  public getProfile(): Observable<UserResponse> {
    return this._httpClient.get(`${this._appConfig.authenticationEndpoint()}account/profile`).pipe(rxjsMap((responseJSON: any) => UserResponse.fromJSON(responseJSON)));
  }

  public changePassword(request: ChangePasswordRequest): Observable<GeneralResponse> {
    return this._httpClient
      .put<GeneralResponse>(`${this._appConfig.authenticationEndpoint()}account/change-password`, request.toJSON())
      .pipe(rxjsMap((response: any) => GeneralResponse.fromJSON(response)));
  }

  public decodeToken(accessToken): TokenResponse {
    return TokenResponse.fromJSON(jwtDecode<JwtPayload>(accessToken));
  }

  public getIsSystemAdmin(): Observable<boolean> {
    return this._httpClient.get<boolean>(`${this._appConfig.authenticationEndpoint()}account/is-system-admin`, {}).pipe(rxjsMap((response: any) => response));
  }
}
