import {LocationStrategy, PathLocationStrategy, registerLocaleData} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import en from '@angular/common/locales/en';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppConfiguration} from '@application/configuration/application-config';
import {APPLICATION_CONFIGURATION} from '@application/configuration/application-config.interface';
import {AppConfigurationService} from '@application/configuration/application-config.service';
import {initializeApplicationConfiguration, initializeL10n} from '@application/configuration/initialize-application';
import {ErrorMessageHelper} from '@application/helper/error-message-helper';
import {CanActivateDefaultPathGuard} from '@application/helper/route-guard/can-activate-default-path.guard';
import {CanActivatePermissionGuard} from '@application/helper/route-guard/can-activate-permission.guard';
import {CanLoadViaAuthGuard} from '@application/helper/route-guard/can-load-via-auth-guard';
import {SUBSCRIPTION} from '@application/helper/subscription/subscription.interface';
import {SubscriptionService} from '@application/helper/subscription/subscription.service';
import {BreakpointResponsivenessViewModeService} from '@application/responsiveness/breakpoint-responsiveness-view-mode.service';
import {RESPONSIVENESS_VIEW_MODE} from '@application/responsiveness/responsiveness-view.mode';
import {LanguageService} from '@application/translation/language/language.service';
import {TranslationHandler} from '@application/translation/translation-handler';
import {TranslationLoader} from '@application/translation/translation-loader';
import {WINDOW_PROVIDERS} from '@application/window/window.service';
import {AUTHENTICATION} from '@infrastructure/http/authentication/http-authentication.interface';
import {HttpAuthenticationService} from '@infrastructure/http/authentication/http-authentication.service';
import {SIGNALR_SERVICE} from '@infrastructure/http/message-centre/signalR.interface';
import {signalRService} from '@infrastructure/http/message-centre/signalR.service';
import {NotificationService} from '@infrastructure/http/notification/notification.service';
import {AgGridModule} from 'ag-grid-angular';
import {LocalStorageModule} from 'angular-2-local-storage';
import {L10nIntlModule, L10nTranslationModule} from 'angular-l10n';
import {map} from 'lodash-es';
import {NgChartjsModule} from 'ng-chartjs';
import {NgOtpInputModule} from 'ng-otp-input';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';
import {AppRouting} from './app.routing.module';
import {Interceptor} from './infrastructure/inteceptor/http-interceptor';
import {CUSTOM_MESSAGE_TOAST} from './presentation/components/custom-message-toast/custom-message-toast';
import {CustomMessageToastComponent} from './presentation/components/custom-message-toast/custom-message-toast.component';
import {CustomMessageToastService} from './presentation/components/custom-message-toast/custom-message-toast.service';
import {SaMessageToastService} from './presentation/components/sa-message-toast/sa-message-toast.service';
import {CommonLayoutComponent} from './presentation/layouts/common-layout/common-layout.component';
import {FullLayoutComponent} from './presentation/layouts/full-layout/full-layout.component';
import {ThemeConstantService} from './shared/services/theme-constant.service';
import {SharedModule} from './shared/shared.module';
import {TemplateModule} from './shared/template/template.module';
registerLocaleData(en);

@NgModule({
  declarations: [AppComponent, CommonLayoutComponent, FullLayoutComponent, CustomMessageToastComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRouting,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
    L10nIntlModule,
    NgOtpInputModule,
    HttpClientModule,
    LocalStorageModule.forRoot({
      prefix: 'sales-app',
      storageType: 'localStorage'
    }),
    L10nTranslationModule.forRoot(
      {
        format: 'language',
        defaultLocale: {language: LanguageService.DEFAULT_LANGUAGE},
        keySeparator: '.',
        providers: [{name: 'app', asset: './assets/i18n'}],
        schema: map(LanguageService.AVAILABLE_LANGUAGES, (language: string) => {
          return {locale: {language}};
        })
      },
      {
        translationLoader: TranslationLoader,
        translationHandler: TranslationHandler
      }
    ),
    ToastrModule.forRoot({
      toastComponent: CustomMessageToastComponent
    }),
    AgGridModule.withComponents([])
  ],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: APPLICATION_CONFIGURATION, useClass: AppConfigurationService},
    {provide: AUTHENTICATION, useClass: HttpAuthenticationService},
    {provide: SUBSCRIPTION, useClass: SubscriptionService},
    {provide: RESPONSIVENESS_VIEW_MODE, useClass: BreakpointResponsivenessViewModeService},
    {provide: CUSTOM_MESSAGE_TOAST, useClass: CustomMessageToastService},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true, animation: {enterDuration: 0, exitDuration: 0}, centered: true}},
    {provide: SIGNALR_SERVICE, useClass: signalRService},
    NotificationService,
    WINDOW_PROVIDERS,
    ThemeConstantService,
    ErrorMessageHelper,
    SaMessageToastService,
    CanActivateDefaultPathGuard,
    CanActivatePermissionGuard,
    CanLoadViaAuthGuard,
    AppConfiguration,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeL10n,
      multi: true,
      deps: [LanguageService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplicationConfiguration,
      multi: true,
      deps: [APPLICATION_CONFIGURATION]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
