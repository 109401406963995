<mat-form-field fxFlex [class.warning]="hasWarning" [class.spinning-input]="isInProgress()" [class.has-prefix]="hasPrefix()">
  <mat-label>
    {{ placeholder }} <span class="optional" *ngIf="isOptional">({{ 'GENERAL.OPTIONAL' | translate | lowercase }})</span>
  </mat-label>

  <span matPrefix *ngIf="canShowColorPreview()" class="color-preview" [style.box-shadow]="'0 3px 6px -2px' + colorPreviewColor" [style.background-color]="colorPreviewColor"></span>

  <input
    #input
    matInput
    [class.url]="!disabled"
    [disabled]="disabled"
    [value]="value"
    [tabIndex]="tabIndex"
    [matTooltipDisabled]="!canShowTooltip"
    [matTooltipPosition]="tooltipPosition"
    [matTooltip]="value"
    (keyup)="preventKeyUpAndDown($event)"
    (keydown)="preventKeyUpAndDown($event)"
    (click)="click()"
    *ngIf="!isInProgress()"
    class="url"
    [class.disabled]="disabled"
  />
  <input #input matInput *ngIf="isInProgress()" />
  <mat-hint *ngIf="canShowHint()">{{ hint }}</mat-hint>

  <button matSuffix mat-icon-button class="mat-icon-action-button-sm-without-border" (click)="delete($event)" *ngIf="canShowDeleteButton()" [disabled]="disabled">
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <mat-spinner diameter="22" *ngIf="isInProgress()" class="spinner-position" mode="indeterminate"></mat-spinner>
</mat-form-field>
