import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';
import {isEmpty, isNil, isUndefined} from 'lodash-es';

@Component({
  selector: 'app-link-form-input',
  templateUrl: './link-form-input.component.html',
  styleUrls: ['./link-form-input.component.scss']
})
export class LinkFormInputComponent implements AfterViewChecked {
  @Input() public placeholder: string;
  @Input() public value: string;
  @Input() public tabIndex = 0;
  @Input() public hasWarning = false;
  @Input() public canDelete = true;
  @Input() public tooltipPosition: TooltipPosition = 'below';
  @Input() public colorPreviewColor: string = null;
  @Input() public isOptional = false;
  @Input() public inProgress = false;
  @Input() public disabled = false;
  @Input() public hint: string;
  @Output() public inputClick: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();
  @Output() public iconClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @ViewChild('input') public input: ElementRef;

  public canShowTooltip: boolean;

  private readonly changeDetectorRef: ChangeDetectorRef;

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
  }

  @HostListener('wheel', ['$event'])
  public onScroll(event: KeyboardEvent): void {
    event.preventDefault();
  }

  @HostListener('keyup.space')
  public onKeyUpSpace(): void {
    this.inputClick.emit(this.input.nativeElement);
  }

  @HostListener('keyup.enter')
  public onKeyUpEnter(): void {
    this.inputClick.emit(this.input.nativeElement);
  }

  public ngAfterViewChecked(): void {
    this.input.nativeElement.readOnly = true;
    this.canShowTooltip = this.checkCanShowTooltip();
    this.changeDetectorRef.detectChanges();
  }

  public preventKeyUpAndDown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  public click(): void {
    this.inputClick.emit(this.input.nativeElement);
    this.input.nativeElement.focus();
  }

  public delete(event: MouseEvent): void {
    if (this.canDelete && !this.disabled) {
      this.iconClick.emit(event);
    }
  }

  public canShowColorPreview(): boolean {
    return !isNil(this.colorPreviewColor);
  }

  public hasPrefix(): boolean {
    return this.canShowColorPreview();
  }

  public isInProgress(): boolean {
    return this.inProgress;
  }

  public canShowDeleteButton(): boolean {
    return this.canDelete && !isEmpty(this.value);
  }

  public canShowHint(): boolean {
    return !isEmpty(this.hint);
  }

  private checkCanShowTooltip(): boolean {
    return !isUndefined(this.input) ? this.input.nativeElement.offsetWidth < this.input.nativeElement.scrollWidth : false;
  }
}
