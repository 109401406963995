import {LanguageService} from '@application/translation/language/language.service';
import {IAppConfiguration} from './application-config.interface';

export function initializeApplicationConfiguration(applicationConfigurationt: IAppConfiguration): () => Promise<void> {
  return () => {
    return applicationConfigurationt.load();
  };
}

export function initializeL10n(languageService: LanguageService): () => Promise<void> {
  return () => {
    return languageService.initialize();
  };
}
