<span [formGroup]="formGroup">
  <mat-form-field appearance="fill">
    <mat-label>{{ inputFieldTitle | translate }}</mat-label>
    <mat-select (closed)="onChange()" [formControlName]="dropdownControl" (infiniteScroll)="getNextBatch()" [threshold]="'30%'" [complete]="offSet === data?.length" msInfiniteScroll>
      <div class="search-div" fxLayout="row">
        <input
          fxLayout="column"
          fxFlex="90"
          class="search-input"
          [formControlName]="searchBoxControl"
          (click)="$event.stopPropagation()"
          (keydown.enter)="search()"
          [placeholder]="searchPlaceHolder | translate"
          (ngModelChange)="filterChanged($event)"
        />
        <mat-icon *ngIf="!isLoading" fxFlex fxLayout="column" fxLayoutAlign="end" class="search-filter-icon" svgIcon="search" (click)="search()"></mat-icon>
        <mat-spinner *ngIf="isLoading" fxFlex fxLayout="column" fxLayoutAlign="end" class="search-spinner" diameter="22" mode="indeterminate"></mat-spinner>
      </div>

      <mat-option *ngFor="let option of options" [value]="option.value" [disabled]="isLoading || option.value === null">{{ option.name }}</mat-option>
    </mat-select>
    <mat-error>{{ getErrorMessage() }}</mat-error>
  </mat-form-field>
</span>
