import {Component, Input} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {TranslateService} from '@application/translation/translate.service';
import {GridOptions} from 'ag-grid-community';
import {isNil} from 'lodash-es';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() public gridOptions: GridOptions;
  @Input() public paginationPageSize;
  @Input() public pageSizeOptions: number[] = [10, 15, 20, 25, 50];

  public pages: number[];

  public constructor(private translateService: TranslateService) {}

  public get currentPage(): number {
    const currentPage = this.gridOptions.api?.paginationGetCurrentPage();
    return !isNil(currentPage) ? currentPage + 1 : 0;
  }

  public get isFirstPage(): boolean {
    return this.currentPage === 1;
  }

  public get isLastPage(): boolean {
    return this.currentPage === this.totalPages;
  }

  public get paginationSummary(): string {
    let paginationSummary = '';

    if (!isNil(this.gridOptions?.api)) {
      const totalRows = this.gridOptions.api.getDisplayedRowCount();

      if (totalRows > 0) {
        const currentPageTotalRows = this.currentPage * this.paginationPageSize;
        const firstDisplayedRow = currentPageTotalRows - this.paginationPageSize + 1;
        const lastDisplayedRow = totalRows < currentPageTotalRows ? totalRows : currentPageTotalRows;
        paginationSummary += this.translateService.instant('GENERAL.AG_GRID.PAGINATION.DISPLAYED_ROW_RANGE', {firstDisplayedRow, lastDisplayedRow});
      }

      paginationSummary += this.translateService.instant('GENERAL.AG_GRID.PAGINATION.DISPLATED_ROW_COUNT', {count: totalRows});
    }

    return paginationSummary;
  }

  public get totalPages(): number {
    const totalPages = this.gridOptions.api?.paginationGetTotalPages();
    return !isNil(totalPages) ? totalPages : 0;
  }

  public get pageNumberLabels(): any[] {
    const paginationRange = 5;
    const pageNumberLabels = [];
    const currentPageExclusions = [1, 2, this.totalPages - 1, this.totalPages];
    const isMiddleEllipse = currentPageExclusions.includes(this.currentPage);
    let isEllipseAdded = false;

    for (let i = 1; i <= this.totalPages; i++) {
      switch (i) {
        case 1:
          pageNumberLabels.push(i);
          break;
        case this.currentPage:
          pageNumberLabels.push(this.currentPage);
          break;
        case this.totalPages:
          pageNumberLabels.push(i);
          break;
        default:
          if (isMiddleEllipse) {
            if (!currentPageExclusions.includes(i) && !isEllipseAdded) {
              pageNumberLabels.push('...');
              isEllipseAdded = true;
            } else if (currentPageExclusions.includes(i)) {
              pageNumberLabels.push(i);
            }
          } else {
            if (pageNumberLabels.length === 1 || pageNumberLabels.length === paginationRange - 2) {
              pageNumberLabels.push('...');
            }
          }
          break;
      }
    }

    return pageNumberLabels;
  }

  public onPageSizeChanged(event: MatSelectChange): void {
    this.paginationPageSize = event.value;
    this.gridOptions.api.paginationSetPageSize(Number(event.value));
  }

  public onPageChanged(event: any): void {
    this.setCurrentPage(Number(event.target.value));
  }

  public goToPreviousPage(): void {
    this.gridOptions.api.paginationGoToPreviousPage();
  }

  public goToNextPage(): void {
    this.gridOptions.api.paginationGoToNextPage();
  }

  public goToFirstPage(): void {
    this.gridOptions.api.paginationGoToFirstPage();
  }

  public goToLastPage(): void {
    this.gridOptions.api.paginationGoToLastPage();
  }

  public setCurrentPage(page: number): void {
    this.gridOptions.api.paginationGoToPage(page - 1);
  }
}
