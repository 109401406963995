import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {CustomMessageToastParams} from './custom-message-toast-params';

export const CUSTOM_MESSAGE_TOAST = new InjectionToken<CustomMessageToast>('CustomMessageToast');

export interface CustomMessageToast {
  toastClickedHandler: Observable<void>;

  showInfoToast(params: CustomMessageToastParams, action?: () => void): void;

  showWarningToast(params: CustomMessageToastParams, action?: () => void): void;

  showErrorToast(params: CustomMessageToastParams, action?: () => void): void;

  showNewUpdateAppliedToast(params: CustomMessageToastParams, action?: () => void): void;

  markToastClicked(): void;
}
