import {Injectable} from '@angular/core';
import {L10nTranslationHandler} from 'angular-l10n/lib/services/l10n-translation-handler';

@Injectable()
export class TranslationHandler implements L10nTranslationHandler {
  public parseValue(key: string, params: any, value: any): string | any {
    if (params) {
      value = value.replace(/{\s?([^{}\s]*)\s?}/g, (substring, parsedKey) => {
        const replacer = params[parsedKey];
        return replacer !== undefined ? replacer : substring;
      });
    }
    return value;
  }
}
