import {Component} from '@angular/core';
import {UserCodeName} from '@infrastructure/user-code-name';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  template: `<span>{{ data | userCodeName }}</span>`
})
export class UserCodeNameRendererComponent implements ICellRendererAngularComp {
  public data?: UserCodeName;

  public agInit(params: ICellRendererParams): void {
    this.data = params.value;
  }

  public refresh(params: any): boolean {
    return false;
  }
}
