import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TranslateService} from '@application/translation/translate.service';
import {BaseComponent} from '@presentation/base-component';
import {AlertDialogResult} from '@presentation/components/alert-dialog/alert-dialog-data';
import {CustomMessageToast, CUSTOM_MESSAGE_TOAST} from '@presentation/components/custom-message-toast/custom-message-toast';
import {DeleteConfirmationDialogComponent} from '@presentation/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import {TooltipCondition} from '@shared/enum/tooltip-condition.enum';
import {cloneDeep, isEmpty, isEqual, isNil} from 'lodash-es';
import {interval} from 'rxjs';
import {FilePreviewComponent} from './file-preview/file-preview.component';
import {UploadFileItem} from './upload-file-item.component';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent extends BaseComponent implements OnInit {
  @Input() public isUploadTitle = true;
  @Input() public title: string;
  @Input() public maximumFileSize: number;
  @Input() public overallFileSize: number;
  @Input() public availableFileExtensions: string[];
  @Input() public files: UploadFileItem[] = [];
  @Input() public isAddingOrEditing: boolean;
  @Input() public isIcon = false;
  @Input() public fileFormatDescription: string;
  @Input() public isPreview = true;
  @Output() outputFiles = new EventEmitter<UploadFileItem[]>();

  public dragAreaClass: string;
  public uploadTitle: string;
  public tooltipCondition = TooltipCondition;
  public isAllValid: TooltipCondition = TooltipCondition.DEFAULT;
  public isFormatValid: TooltipCondition = TooltipCondition.DEFAULT;
  public isSizeValid: TooltipCondition = TooltipCondition.DEFAULT;
  private _translateService: TranslateService;
  private _dialog: MatDialog;
  private _dialogConfig: MatDialogConfig = {
    autoFocus: false,
    restoreFocus: false
  };
  private readonly _customMessageToast: CustomMessageToast;

  public constructor(translateService: TranslateService, dialog: MatDialog, @Inject(CUSTOM_MESSAGE_TOAST) customMessageToast: CustomMessageToast) {
    super();
    this._translateService = translateService;
    this._dialog = dialog;
    this._customMessageToast = customMessageToast;
  }

  @HostListener('dragover', ['$event']) onDragOver(event: any): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) onDragEnter(event: any): void {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) onDragEnd(event: any): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event: any): void {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) onDrop(event: any): void {
    if (!this.isAddingOrEditing) {
      return;
    }

    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      const files: File = event.dataTransfer.files;
      this.addFiles(files);
    }
  }

  ngOnInit(): void {
    this.title = this._translateService.instant(this.title);
    this.uploadTitle = `${this._translateService.instant('GENERAL.ACTIONS.UPLOAD')}${this.title}`.replace(/(\r\n|\n|\r)/gm, '');
  }

  public onFileChange(event: any): void {
    if (!this.isAddingOrEditing) {
      return;
    }

    const files = event.target.files;
    this.addFiles(files);
    event.target.value = '';
  }

  public deleteFile(file: UploadFileItem): void {
    if (!this.isAddingOrEditing) {
      return;
    }

    this._dialog
      .open(DeleteConfirmationDialogComponent, this._dialogConfig)
      .afterClosed()
      .subscribe((res: any) => {
        if (res === AlertDialogResult.CONFIRM) {
          this.showSuccessToast();
          this.files = this.files.filter((item: UploadFileItem) => item !== file);
          if (this.isIcon && this.isAddingOrEditing) {
            this.checkOverallValidation();
          }
          this.outputFiles.emit(this.files);
        }
      });
  }

  public previewFile(file: UploadFileItem): void {
    if (file.errorMessage || file.uploadDetail.isUploading) {
      return;
    }

    const dialogRef = this._dialog.open(FilePreviewComponent, {
      autoFocus: false,
      restoreFocus: false,
      height: '',
      width: '800px'
    });

    let files = cloneDeep(this.files);
    files = files.filter((f: UploadFileItem) => !isEqual(f, file));
    files = files.filter((f: UploadFileItem) => isNil(f.errorMessage));
    files.unshift(file);

    const instance = dialogRef.componentInstance;
    instance.files = files;
    instance.title = this.title;
  }

  public downloadFile(selectedFile: UploadFileItem): void {
    if (isNil(selectedFile?.file)) {
      const url = selectedFile.review;
      window.open(url);
    } else {
      const blob = new Blob([selectedFile.file], {type: selectedFile.file.type});
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.setAttribute('style', 'display: none');
      anchor.href = url;
      anchor.download = isNil(selectedFile?.file) ? selectedFile.name : selectedFile.file.name;
      anchor.click();
      window.URL.revokeObjectURL(url);
      anchor.remove();
    }
  }

  public get getTotalItem(): number {
    return this.files.length;
  }

  public checkOverallValidation(): void {
    if (isEmpty(this.files)) {
      this.isFormatValid = this.tooltipCondition.DEFAULT;
      this.isAllValid = this.tooltipCondition.DEFAULT;
      this.isSizeValid = this.tooltipCondition.DEFAULT;
    } else {
      let overallSize = 0;
      let isAllFormatValid = true;
      this.files.forEach((file: UploadFileItem) => {
        const size = file.size.replace(new RegExp('[^\\d.]', 'g'), '');
        overallSize += file.size.includes('MB') ? parseInt(size, 10) : parseInt(size, 10) / 1000;
        if (!this.availableFileExtensions.includes(file.format)) {
          isAllFormatValid = false;
        }
      });
      this.isFormatValid = this.tooltipCondition[!isAllFormatValid ? 'INVALID' : 'VALID'];
      this.isSizeValid = this.tooltipCondition[overallSize > this.overallFileSize ? 'INVALID' : 'VALID'];
      this.isAllValid = this.tooltipCondition[this.isFormatValid === this.tooltipCondition.INVALID || this.isSizeValid === this.tooltipCondition.INVALID ? 'INVALID' : 'VALID'];
    }
  }

  private addFiles(files: any): void {
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        const size = file.size >= 1000000 ? `${Math.round(file.size / 1000000)} MB` : `${Math.round(file.size / 1000)} KB`;
        let format = file.name.split('.').pop()?.toLowerCase();
        if (format === 'doc' || format === 'xls') {
          format = format + 'x';
        }

        let errorMessage: string;
        if (file.size > this.maximumFileSize * 1000000) {
          errorMessage = this._translateService.instant('GENERAL.ERRORS.FILES.FILE_SIZE_EXCEEDED_DESCRIPTION', {
            size: `${this.maximumFileSize}MB`
          });
        } else if (!this.availableFileExtensions.includes(format)) {
          errorMessage = this._translateService.instant('GENERAL.ERRORS.FILES.INVALID_FILE_FORMAT_DESCRIPTION', {
            fileExtensions: this.availableFileExtensions.join().toUpperCase()
          });
        }

        const uploadFileItem: UploadFileItem = {
          review: isNil(errorMessage) ? (reader.result as string) : '/assets/images/illustration/default.svg',
          displayName: file.name,
          name: file.name,
          size,
          format,
          uploadDetail: {
            isUploading: true,
            currentSec: 0,
            progressbarValue: 0
          },
          errorMessage,
          file
        };
        this.files.unshift(uploadFileItem);
        if (this.isIcon && this.isAddingOrEditing) {
          this.checkOverallValidation();
        }
        this.startLoading(uploadFileItem);
      };
    }
  }

  private showSuccessToast(): void {
    this._customMessageToast.showInfoToast({
      tapToDismiss: false,
      timeOut: 5000,
      title: this._translateService.instant('GENERAL.SUCCESSFULLY_DELETED_TITLE'),
      message: this._translateService.instant('GENERAL.SUCCESSFULLY_DELETED_DESCRIPTION', {count: 1}),
      positionClass: 'toast-top-right'
    });
  }

  private startLoading(file: UploadFileItem): void {
    const timer = interval(1000);
    const subscription = timer.subscribe((sec: any) => {
      file.uploadDetail.progressbarValue = 25 * sec;
      file.uploadDetail.currentSec = sec;

      if (file.uploadDetail.currentSec === 4) {
        this.outputFiles.emit(this.files);
        subscription.unsubscribe();
        setTimeout(() => {
          file.uploadDetail.isUploading = false;
        }, 800);
      }
    });
  }
}
