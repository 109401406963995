import {Injectable} from '@angular/core';
import {isUndefined} from 'lodash-es';
import {ActiveToast, ToastrService} from 'ngx-toastr';
import {Observable, Subject} from 'rxjs';
import {CustomMessageToast} from './custom-message-toast';
import {CustomMessageToastParams} from './custom-message-toast-params';

@Injectable()
export class CustomMessageToastService implements CustomMessageToast {
  private readonly toastr: ToastrService;
  private readonly toastClicked: Subject<void> = new Subject();

  public constructor(toastr: ToastrService) {
    this.toastr = toastr;
  }

  public get toastClickedHandler(): Observable<void> {
    return this.toastClicked.asObservable();
  }

  public showInfoToast(params: CustomMessageToastParams, action?: () => void): void {
    this.showToast(params, 'info-1', 'info', action);
  }

  public showErrorToast(params: CustomMessageToastParams, action?: () => void): void {
    this.showToast(params, 'error-full-52px', 'error', action);
  }

  public showWarningToast(params: CustomMessageToastParams, action?: () => void): void {
    this.showToast(params, 'warning-full-52px', 'warning', action);
  }

  public showNewUpdateAppliedToast(params: CustomMessageToastParams, action?: () => void): void {
    this.showToast(params, 'update-blue', 'info', action);
  }

  public showSuccessToast(params: CustomMessageToastParams, action?: () => void): void {
    this.showToast(params, 'tick', 'info', action);
  }

  public markToastClicked(): void {
    this.toastClicked.next();
  }

  private getActiveToast(params: CustomMessageToastParams): ActiveToast<any> {
    const {tapToDismiss, timeOut, message, title, extendedTimeOut, positionClass} = params;

    this.toastr.toastrConfig.tapToDismiss = tapToDismiss;
    this.toastr.toastrConfig.timeOut = timeOut;
    this.toastr.toastrConfig.positionClass = positionClass;
    this.toastr.toastrConfig.preventDuplicates = true;
    this.toastr.toastrConfig.resetTimeoutOnDuplicate = true;

    if (!isUndefined(extendedTimeOut)) {
      this.toastr.toastrConfig.extendedTimeOut = extendedTimeOut;
    }

    this.toastr.show(message, title).onTap.subscribe(() => this.toastClicked.next());

    return this.toastr.show(message, title);
  }

  private showToast(params: CustomMessageToastParams, titleIcon: string, statusBarClass: string, action?: () => void): void {
    const toast = this.getActiveToast(params);

    toast.toastRef.componentInstance.titleIcon = titleIcon;
    toast.toastRef.componentInstance.statusBarClass = statusBarClass;
    toast.toastRef.componentInstance.action = action;
  }
}
