import {NgModule} from '@angular/core';
import {TranslateAsyncPipe} from './pipes/translate-async.pipe';
import {TranslatePipe} from './pipes/translate.pipe';
import {TranslateService} from './translate.service';

@NgModule({
  declarations: [TranslatePipe, TranslateAsyncPipe],
  providers: [TranslateService],
  exports: [TranslatePipe, TranslateAsyncPipe]
})
export class TranslationModule {}
