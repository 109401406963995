import {Component, OnInit} from '@angular/core';
import {ThemeConstantService} from '../../services/theme-constant.service';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html'
})
export class QuickViewComponent implements OnInit {
  private selectedHeaderColor: string;
  private isSideNavDark: boolean;
  private isFolded: boolean;
  private themeService: ThemeConstantService;

  public constructor(themeService: ThemeConstantService) {
    this.themeService = themeService;
  }

  public ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe((isFolded) => (this.isFolded = isFolded));
    this.themeService.isSideNavDarkChanges.subscribe((isDark) => (this.isSideNavDark = isDark));
    this.themeService.selectedHeaderColor.subscribe((color) => (this.selectedHeaderColor = color));
  }

  public changeHeaderColor() {
    this.themeService.changeHeaderColor(this.selectedHeaderColor);
  }

  public toggleSideNavDark() {
    this.themeService.toogleSideNavDark(this.isSideNavDark);
  }

  public toggleFold() {
    this.themeService.toggleFold(this.isFolded);
  }
}
