export enum StatusType {
  GENERAL_STATUS = 1,
  REGISTRATION_STATUS,
  CUSTOMER_STATUS,
  PRICE_STATUS,
  PRICE_VERIFICATION_STATUS,
  SALES_STATUS,
  SALES_VERIFICATION_STATUS,
  CUSTOMER_VERIFICATION_STATUS,
  ORDER_STATUS,
  INVOICE_STATUS,
  PAYMENT_STATUS,
  AX_LOG_STATUS,
  ORDER_DELIVERY_RECORD_STATUS,
  ORDER_DELIVERY_FORM_STATUS,
  SALESFORCE_SYNC_STATUS,
  ERP_CUSTOMER_SYNC_STATUS,
  PHONE_USE_STATUS,
}
