import {Language} from '@application/translation/language/language.enum';
import {Status} from '@domain/status.enum';
import {LocalizedName} from '@infrastructure/http/localized-name';
import {IdName} from '@infrastructure/id-name';
import {isNil} from 'lodash-es';

export class UserResponse {
  public readonly id: number = null;
  public readonly employeeCode: string = null;
  public readonly localizedNames: LocalizedName[] = [];
  public readonly phoneNumber: string = null;
  public readonly email: string = null;
  public readonly position: IdName = null;
  public readonly defaultArea: IdName = null;
  public readonly defaultRole: IdName = null;
  public readonly avatarUrl: string = null;
  public readonly isSystemAdmin: boolean = null;
  public readonly status: Status = null;
  public readonly modifiedAt: Date = null;
  public readonly isCustomerSupport: boolean = null;
  public readonly name: string = null;

  public constructor(data: any = null) {
    if (!isNil(data)) {
      Object.assign(this, data);
      this.status = data?.isActive ? Status.ACTIVE : Status.INACTIVE;
      this.localizedNames = data?.localizedNames?.map((m: LocalizedName) => LocalizedName.fromJSON(m));
      this.name = data?.localizedNames?.find((n: LocalizedName) => Language[n.language] === Language.ZH)?.name;
      this.position = data.position ? IdName.fromJSON(data.position) : null;
      this.defaultArea = data.defaultArea ? IdName.fromJSON(data.defaultArea) : null;
    }
  }

  public static fromJSON(data: any): UserResponse {
    return new UserResponse(data);
  }
}
