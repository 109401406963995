<div fxLayout="column">
  <div class="datepicker-header" fxLayout="row" fxFlex="72px" fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <button mat-button class="current-period" (click)="currentPeriodClicked()">
        <h3 class="title font-weight-semibold" fxFlex>{{ periodButtonText }}</h3>
        <mat-icon svgIcon="chevron-up"></mat-icon>
      </button>
    </div>
    <div class="controls" fxLayout="column">
      <div fxLayout="row">
        <button mat-icon-button type="button" class="mat-icon-action-button-sm-without-border" [disabled]="!previousEnabled()" (click)="previousClicked()" [attr.aria-label]="prevButtonLabel">
          <mat-icon svgIcon="chevron-left"></mat-icon>
        </button>
        <button mat-icon-button type="button" class="mat-icon-action-button-sm-without-border" [disabled]="!nextEnabled()" (click)="nextClicked()" [attr.aria-label]="nextButtonLabel">
          <mat-icon svgIcon="chevron-right"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
