export class BusinessUnitResponse {
  private _id: number | null = null;
  private _englishName: string | null = null;
  private _chineseName: string | null = null;

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get englishName(): string {
    return this._englishName;
  }
  public set englishName(value: string) {
    this._englishName = value;
  }

  public get chineseName(): string {
    return this._chineseName;
  }
  public set chineseName(value: string) {
    this._chineseName = value;
  }

  public static fromJSON(data: any): BusinessUnitResponse {
    return new BusinessUnitResponse(), data;
  }
}
