import {ItemType} from './item-type.enum';

export enum ItemCategory {
  WHOLE_MACHINE = 1,
  SPARE_PART,
  ACCESSORY
}

export const ITEM_CATEGORY_TYPE_MAPPING = new Map<ItemType, ItemCategory[]>();
ITEM_CATEGORY_TYPE_MAPPING.set(ItemType.WHOLE_MACHINE, [ItemCategory.WHOLE_MACHINE]);
ITEM_CATEGORY_TYPE_MAPPING.set(ItemType.SPARE_PART, [ItemCategory.SPARE_PART, ItemCategory.ACCESSORY]);

export const CONTAIN_PRODUCT_SPAREPART = [ItemCategory.WHOLE_MACHINE, ItemCategory.SPARE_PART];
