import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {IHeaderAngularComp} from 'ag-grid-angular';
import {IHeaderParams} from 'ag-grid-community/main';
import {isEqual, isUndefined} from 'lodash-es';

@Component({
  styleUrls: ['./grid-header-name.component.scss'],
  templateUrl: 'grid-header-name.component.html'
})
export class GridHeaderNameComponent implements AfterViewChecked, IHeaderAngularComp {
  @ViewChild('headerText') public headerText: ElementRef;
  public refresh;
  public params: IHeaderParams;
  public sorted: string;
  public tooltipHideDelayMs = 1000;
  public tooltipShowDelayMs = 2000;
  public showTooltip = false;
  private readonly changeDetectorRef: ChangeDetectorRef;

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
  }

  public agInit(params: IHeaderParams): void {
    this.params = params;
    if (params.enableSorting) {
      this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
      this.onSortChanged();
    }
  }

  public ngAfterViewChecked(): void {
    this.showTooltip = this.canShowTooltip();
    this.changeDetectorRef.detectChanges();
  }

  public onOwnSortRequested(event: any): void {
    switch (this.sorted) {
      case '':
        this.params.setSort('asc', event.shiftKey);
        break;
      case 'asc':
        this.params.setSort('desc', event.shiftKey);
        break;
      case 'desc':
        this.params.progressSort();
    }
  }

  public onSortChanged(): void {
    if (this.params.column.isSortAscending()) {
      this.sorted = 'asc';
    } else if (this.params.column.isSortDescending()) {
      this.sorted = 'desc';
    } else {
      this.sorted = '';
    }
  }

  public isSortingEnabled(): boolean {
    return this.params.enableSorting;
  }

  public isSortingDirectionAscending(): boolean {
    return this.isSortingEnabled() && isEqual(this.sorted, 'asc');
  }

  public isSortingDirectionDescending(): boolean {
    return this.isSortingEnabled() && isEqual(this.sorted, 'desc');
  }

  private canShowTooltip(): boolean {
    return !isUndefined(this.headerText) && this.headerText.nativeElement.offsetWidth < this.headerText.nativeElement.scrollWidth;
  }
}
