import {Inject, Injectable} from '@angular/core';
import {APPLICATION_CONFIGURATION, IAppConfiguration} from '@application/configuration/application-config.interface';
import {replace} from 'lodash-es';

@Injectable()
export class AppConfiguration {
  private _applicationConfig: IAppConfiguration;

  public constructor(@Inject(APPLICATION_CONFIGURATION) applicationConfig: IAppConfiguration) {
    this._applicationConfig = applicationConfig;
  }

  public authenticationEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.authenticationEndpoint, version);
  }

  public customerEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.customerEndpoint, version);
  }

  public organizationEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.organizationEndpoint, version);
  }

  public productCatalogueEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.productCatalogueEndpoint, version);
  }

  public salesEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.salesEndpoint, version);
  }

  public settingsEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.settingsEndpoint, version);
  }

  public ordersEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.ordersEndpoint, version);
  }

  public paymentsEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.paymentsEndpoint, version);
  }

  public messagesEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.messagesEndpoint, version);
  }

  public quotationsEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.quotationsEndpoint, version);
  }

  public deliveryExpressEndPoint(): string {
    return this._applicationConfig.get().origin.deliveryExpressEndPoint;
  }

  public axServiceEndPoint(): string {
    return this._applicationConfig.get().origin.axServiceEndPoint;
  }

  public blobStorageEndPoint(): string {
    return this._applicationConfig.get().origin.blobStorageEndPoint;
  }

  public salesforceEndpoint(version: number = 1): string {
    return this.setEndpointVersion(this._applicationConfig.get().origin.salesforceEndpoint, version);
  }

  public get isPreviewMode(): boolean {
    return this._applicationConfig.get().isPreviewMode;
  }

  public get blobEndpoint(): string {
    return this._applicationConfig.get().blobConfig.blobEndpoint;
  }

  public get isNotificationEnabled(): boolean {
    return this._applicationConfig.get().isNotificationEnabled;
  }

  private setEndpointVersion(baseEndpointUrl: string, version: number): string {
    return replace(baseEndpointUrl, '{version}', `${version}`);
  }
}
