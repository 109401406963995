import {Inject, Injectable} from '@angular/core';
import {CanLoad, Navigation, Params, Route, Router, UrlSegment} from '@angular/router';
import {RouteUtils} from '@application/routing/route-utils';
import {AUTHENTICATION, IAuthenticationService} from '@infrastructure/http/authentication/http-authentication.interface';
import {isNil} from 'lodash-es';

@Injectable()
export class CanLoadViaAuthGuard implements CanLoad {
  private readonly _router: Router;
  private readonly _authenticationService: IAuthenticationService;

  public constructor(router: Router, @Inject(AUTHENTICATION) authentication: IAuthenticationService) {
    this._router = router;
    this._authenticationService = authentication;
  }

  public canLoad(route: Route, segments: UrlSegment[]): boolean {
    let result = false;

    //temp solution
    const user = this._authenticationService.getUserCode();
    if (!isNil(user)) {
      result = true;
    } else {
      let queryParams: Params = [];
      const navigation: Navigation = this._router.getCurrentNavigation();
      if (!isNil(navigation) && !isNil(navigation.extractedUrl)) {
        queryParams = navigation.extractedUrl.queryParams;
      }
      this._router.navigate([RouteUtils.paths.authentication.login.absolutePath], {queryParams: {redirect: RouteUtils.buildPathFromSegmentsAndQueryParams(segments, queryParams)}});
    }
    return result;
  }
}
