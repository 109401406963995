export class AlertDialogData {
  private static readonly okText: string = 'GENERAL.OK';
  private static readonly cancelText: string = 'GENERAL.ACTIONS.CANCEL';
  private readonly _illustrationName: string;
  private readonly _titleText: string;
  private readonly _messageText: string;
  private readonly _extraInformationText: string;
  private readonly _extraComponent: any;
  private readonly _cancelButtonText: string;
  private readonly _confirmButtonText: string;
  private readonly _confirmationTypes: AlertDialogConfirmationType[];
  private readonly _isShowExtraInformation: boolean;

  public constructor(
    titleText: string,
    messageText: string,
    illustrationName: string = 'alert-info',
    cancelButtonText: string = '',
    confirmationTypes: AlertDialogConfirmationType[] = [AlertDialogConfirmationType.CONFIRM],
    confirmButtonText: string = AlertDialogData.okText,
    extraInformationText?: string,
    extraComponent?: any,
    isShowExtraInformation?: boolean
  ) {
    this._titleText = titleText;
    this._messageText = messageText;
    this._illustrationName = illustrationName;
    this._cancelButtonText = cancelButtonText;
    this._confirmationTypes = confirmationTypes;
    this._confirmButtonText = confirmButtonText;
    this._extraInformationText = extraInformationText;
    this._extraComponent = extraComponent;
    this._isShowExtraInformation = isShowExtraInformation;
  }

  public get titleText(): string {
    return this._titleText;
  }

  public get messageText(): string {
    return this._messageText;
  }

  public get illustrationName(): string {
    return this._illustrationName;
  }

  public get extraInformationText(): string {
    return this._extraInformationText;
  }

  public get extraComponent(): any {
    return this._extraComponent;
  }

  public get confirmationTypes(): AlertDialogConfirmationType[] {
    return this._confirmationTypes;
  }

  public get confirmButtonText(): string {
    return this._confirmButtonText;
  }

  public get cancelButtonText(): string {
    return this._cancelButtonText;
  }

  public get isShowExtraInformation(): boolean {
    return this._isShowExtraInformation;
  }

  public static createSuccessData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(titleText, messageText, 'alert-success');
  }

  public static createErrorData(titleText: string, messageText: string, extraInformationText?: string, isReport: boolean = false, extraUrl?: string): AlertDialogData {
    const alertDialogConfirmationType = isReport ? AlertDialogConfirmationType.REPORT : AlertDialogConfirmationType.CONFIRM;
    const buttonText = isReport ? null : AlertDialogData.okText;
    return new AlertDialogData(titleText, messageText, 'alert-error', '', [alertDialogConfirmationType], buttonText, extraInformationText, extraUrl);
  }

  public static createCustomErrorData(titleText: string, messageText: string, extraComponent?: any): AlertDialogData {
    return new AlertDialogData(titleText, messageText, 'alert-error', '', [AlertDialogConfirmationType.CONFIRM], AlertDialogData.okText, null, extraComponent);
  }

  public static createInformativeData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(titleText, messageText);
  }

  public static createConfirmationData(titleText: string, messageText: string, customAlert?: any): AlertDialogData {
    return new AlertDialogData(titleText, messageText, customAlert == null ? 'alert-confirmation' : customAlert, AlertDialogData.cancelText, [AlertDialogConfirmationType.CONFIRM], null);
  }

  public static createDeleteData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(titleText, messageText, 'alert-delete', AlertDialogData.cancelText, [AlertDialogConfirmationType.DELETE], null);
  }

  public static createDeleteOrSaveData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(titleText, messageText, '', AlertDialogData.cancelText, [AlertDialogConfirmationType.CONFIRM, AlertDialogConfirmationType.DELETE], 'GENERAL.ACTIONS.SAVE');
  }

  public static createDiscardData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(titleText, messageText, 'alert-discard', AlertDialogData.cancelText, [AlertDialogConfirmationType.CONFIRM], null);
  }

  public static createSystemMessageData(titleText: string, messageText: string): AlertDialogData {
    return new AlertDialogData(titleText, messageText, 'alert-info');
  }
}

export enum AlertDialogConfirmationType {
  CONFIRM,
  DELETE,
  REPORT
}

export enum AlertDialogResult {
  CANCEL,
  CONFIRM,
  DELETE
}
