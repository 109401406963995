import {Pipe, PipeTransform} from '@angular/core';
import {CustomerStatus} from '@domain/customer/customer-status.enum';
import {RegistrationStatus} from '@domain/customer/registration-status.enum';
import {ErpCustomerSyncStatus} from '@domain/erp-customer/erp-customer-sync-status.enum';
import {PriceStatus} from '@domain/product-catalogue/price-status.enum';
import {PriceVerificationStatus} from '@domain/product-catalogue/price-verification-status.enum';
import {CustomerVerificationStatus} from '@domain/sales/customer-verification-status.enum';
import {AxLogStatus} from '@domain/sales/order/ax-log-status';
import {DeliveryFormStatus} from '@domain/sales/order/delivery-form-status.enum';
import {DeliverRecordStatus} from '@domain/sales/order/delivery-record-status.enum';
import {InvoiceStatus} from '@domain/sales/order/invoice-status.enum';
import {OrderStatus} from '@domain/sales/order/order-status.enum';
import {PaymentStatus} from '@domain/sales/payment/payment-status.enum';
import {SalesStatus} from '@domain/sales/sales-status.enum';
import {SalesVerificationStatus} from '@domain/sales/sales-verification-status.enum';
import {SalesforceSyncStatus} from '@domain/salesforce/salesforce-sync-status.enum';
import {StatusType} from '@domain/status-type.enum';
import {Status} from '@domain/status.enum';
import {isNil, kebabCase} from 'lodash-es';
import {StatusColor} from '../../domain/status-color.enum';

@Pipe({
  name: 'statusClass'
})
export class StatusClassPipe implements PipeTransform {
  public transform(status: any, statusType: StatusType): string {
    if (!isNil(status) && !isNil(statusType)) {
      let statusClass = 'status-icon';
      let statusColor: StatusColor;

      for (const dict of this.statusColorDictionary.get(statusType)?.entries()) {
        if (dict[1].includes(status)) {
          statusColor = dict[0];
          break;
        }
      }

      if (!isNil(statusColor)) {
        statusClass += ` ${kebabCase(StatusColor[statusColor])}-status`;
      }

      return statusClass;
    }
  }

  private get statusColorDictionary(): any {
    const statusColors = new Map<StatusType, Map<StatusColor, any[]>>()
      .set(StatusType.GENERAL_STATUS, new Map<StatusColor, Status[]>().set(StatusColor.GREEN, [Status.ACTIVE]).set(StatusColor.GREY, [Status.INACTIVE]))
      .set(
        StatusType.REGISTRATION_STATUS,
        new Map<StatusColor, RegistrationStatus[]>()
          .set(StatusColor.GREY, [RegistrationStatus.PENDING_INPUT])
          .set(StatusColor.YELLOW, [RegistrationStatus.REVERIFY, RegistrationStatus.REJECTED])
          .set(StatusColor.ORANGE, [RegistrationStatus.PENDING_APPROVAL])
          .set(StatusColor.GREEN, [RegistrationStatus.APPROVED])
          .set(StatusColor.DARK_GREY, [RegistrationStatus.END_MANUAL_ADD])
      )
      .set(
        StatusType.CUSTOMER_STATUS,
        new Map<StatusColor, CustomerStatus[]>().set(StatusColor.GREEN, [CustomerStatus.ACTIVE]).set(StatusColor.GREY, [CustomerStatus.INACTIVE]).set(StatusColor.DARK_YELLOW, [CustomerStatus.FROZEN])
      )
      .set(
        StatusType.PRICE_STATUS,
        new Map<StatusColor, PriceStatus[]>()
          .set(StatusColor.GREEN, [PriceStatus.ACTIVE])
          .set(StatusColor.DARK_GREY, [PriceStatus.INACTIVE])
          .set(StatusColor.GREY, [PriceStatus.NONE])
          .set(StatusColor.YELLOW, [PriceStatus.PENDING])
      )
      .set(
        StatusType.PRICE_VERIFICATION_STATUS,
        new Map<StatusColor, PriceVerificationStatus[]>()
          .set(StatusColor.GREY, [PriceVerificationStatus.PENDING_INPUT])
          .set(StatusColor.DARK_GREY, [PriceVerificationStatus.REJECTED])
          .set(StatusColor.YELLOW, [PriceVerificationStatus.REVERIFY])
          .set(StatusColor.ORANGE, [PriceVerificationStatus.PENDING_SEND_TO_BPM, PriceVerificationStatus.PENDING_BPM_APPROVAL])
          .set(StatusColor.GREEN, [PriceVerificationStatus.APPROVED])
      )
      .set(
        StatusType.SALES_STATUS,
        new Map<StatusColor, SalesStatus[]>().set(StatusColor.GREEN, [SalesStatus.ACTIVE]).set(StatusColor.DARK_GREY, [SalesStatus.INACTIVE]).set(StatusColor.GREY, [SalesStatus.NONE])
      )
      .set(
        StatusType.SALES_VERIFICATION_STATUS,
        new Map<StatusColor, SalesVerificationStatus[]>()
          .set(StatusColor.GREY, [SalesVerificationStatus.DRAFT, SalesVerificationStatus.NONE])
          .set(StatusColor.DARK_GREY, [SalesVerificationStatus.REJECTED])
          .set(StatusColor.YELLOW, [SalesVerificationStatus.REVERIFY])
          .set(StatusColor.ORANGE, [SalesVerificationStatus.PENDING_SEND_TO_BPM, SalesVerificationStatus.PENDING_BPM_APPROVAL])
          .set(StatusColor.GREEN, [SalesVerificationStatus.APPROVED])
      )
      .set(
        StatusType.CUSTOMER_VERIFICATION_STATUS,
        new Map<StatusColor, CustomerVerificationStatus[]>()
          .set(StatusColor.GREY, [CustomerVerificationStatus.NONE])
          .set(StatusColor.YELLOW, [CustomerVerificationStatus.PENDING_SENT])
          .set(StatusColor.ORANGE, [CustomerVerificationStatus.SENT])
          .set(StatusColor.GREEN, [CustomerVerificationStatus.END, CustomerVerificationStatus.END_PENDING_SIGNED_COPY, CustomerVerificationStatus.CONFIRMED])
          .set(StatusColor.DARK_GREY, [CustomerVerificationStatus.REJECTED, CustomerVerificationStatus.REJECTED_END, CustomerVerificationStatus.REJECTED_REDO, CustomerVerificationStatus.CANCELLED])
      )
      .set(
        StatusType.ORDER_STATUS,
        new Map<StatusColor, OrderStatus[]>()
          .set(StatusColor.GREY, [OrderStatus.PENDING_PROCESS])
          .set(StatusColor.YELLOW, [OrderStatus.PROCESSING])
          .set(StatusColor.DARK_GREY, [OrderStatus.REJECTED_END, OrderStatus.CANCELLED, OrderStatus.END_NO_DELIVERY])
      )
      .set(
        StatusType.PAYMENT_STATUS,
        new Map<StatusColor, PaymentStatus[]>()
          .set(StatusColor.RED, [PaymentStatus.PENDING_PAYMENT])
          .set(StatusColor.YELLOW, [PaymentStatus.PAYING])
          .set(StatusColor.GREEN, [PaymentStatus.PAID])
          .set(StatusColor.DARK_GREY, [PaymentStatus.CANCELLED])
      )
      .set(
        StatusType.INVOICE_STATUS,
        new Map<StatusColor, InvoiceStatus[]>().set(StatusColor.GREY, [InvoiceStatus.NONE]).set(StatusColor.ORANGE, [InvoiceStatus.CREATED]).set(StatusColor.YELLOW, [InvoiceStatus.SENT])
      )
      .set(
        StatusType.AX_LOG_STATUS,
        new Map<StatusColor, AxLogStatus[]>().set(StatusColor.GREEN, [AxLogStatus.SUCCESS]).set(StatusColor.YELLOW, [AxLogStatus.FAIL]).set(StatusColor.RED, [AxLogStatus.NO_RESPONSE])
      )
      .set(
        StatusType.ORDER_DELIVERY_RECORD_STATUS,
        new Map<StatusColor, DeliverRecordStatus[]>()
          .set(StatusColor.GREY, [DeliverRecordStatus.PENDING_PROCESS, DeliverRecordStatus.PENDING_AX_ORDER])
          .set(StatusColor.YELLOW, [DeliverRecordStatus.PROCESSING])
          .set(StatusColor.GREEN, [DeliverRecordStatus.COMPLETED])
          .set(StatusColor.DARK_GREY, [DeliverRecordStatus.NO_SHIPMENT])
      )
      .set(
        StatusType.ORDER_DELIVERY_FORM_STATUS,
        new Map<StatusColor, DeliveryFormStatus[]>()
          .set(StatusColor.GREY, [DeliveryFormStatus.PENDING_SHIPMENT, DeliveryFormStatus.PENDING_AX_ORDER])
          .set(StatusColor.YELLOW, [DeliveryFormStatus.SHIPPING])
          .set(StatusColor.GREEN, [DeliveryFormStatus.COMPLETED])
          .set(StatusColor.RED, [DeliveryFormStatus.LATE_SHIPMENT])
          .set(StatusColor.ORANGE, [DeliveryFormStatus.DELIVERED])
          .set(StatusColor.DARK_GREY, [DeliveryFormStatus.NO_SHIPMENT])
      )
      .set(
        StatusType.SALESFORCE_SYNC_STATUS,
        new Map<StatusColor, SalesforceSyncStatus[]>()
          .set(StatusColor.DARK_RED, [SalesforceSyncStatus.NOT_SYNC])
          .set(StatusColor.BRIGHT_YELLOW, [SalesforceSyncStatus.UPDATED])
          .set(StatusColor.GREEN, [SalesforceSyncStatus.SYNCED])
      )
      .set(
        StatusType.ERP_CUSTOMER_SYNC_STATUS,
        new Map<StatusColor, ErpCustomerSyncStatus[]>()
          .set(StatusColor.RED, [ErpCustomerSyncStatus.NOT_SYNC])
          .set(StatusColor.ORANGE, [ErpCustomerSyncStatus.UPDATED])
          .set(StatusColor.GREEN, [ErpCustomerSyncStatus.SYNCED])
      )
      .set(StatusType.PHONE_USE_STATUS, new Map<StatusColor, Status[]>().set(StatusColor.GREEN, [Status.ACTIVE]).set(StatusColor.GREY, [Status.INACTIVE]));

    return statusColors;
  }
}
