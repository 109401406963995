import {ChangeDetectorRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {filter, find, first, forEach, includes, isEmpty, isEqual, isNil, size} from 'lodash-es';
import {StringUtils} from './string-utils';

export class FormValidationHelper {
  private static allowedElements = ['mat-form-field', 'input', 'button', 'span'];

  public checkForm(form: FormGroup, document: Document): boolean {
    return this.checkForms([form], document);
  }

  public checkForms(forms: FormGroup[], document: Document, changeDetectorRef: ChangeDetectorRef = null): boolean {
    forms = filter(forms, (form: FormGroup) => !isNil(form));
    forEach(forms, (form: FormGroup) => {
      form.markAllAsTouched();

      if (!isNil(changeDetectorRef)) {
        changeDetectorRef.detectChanges();
      }
    });
    return this.checkDocumentFormControls(document);
  }

  public scrollToFirstInvalidAndTouchedFormField(document: Document): void {
    const invalidAndTouchedElements: HTMLElement[] = this.findInvalidControlsInForms(document, '.ng-invalid.ng-touched, .mat-form-field-invalid.ng-touched, .tab-error');

    if (!isEmpty(invalidAndTouchedElements)) {
      this.scrollToFirstInvalidFormElement(invalidAndTouchedElements);
    }
  }

  private checkDocumentFormControls(document: Document): boolean {
    const invalidElements: HTMLElement[] = this.findInvalidControlsInForms(document);
    const isFormValid = isEmpty(invalidElements);
    if (!isFormValid) {
      this.scrollToFirstInvalidFormElement(invalidElements);
    }
    return isFormValid;
  }

  private findInvalidControlsInForms(document: Document, query: string = '.ng-invalid, .mat-form-field-invalid, .tab-error'): HTMLElement[] {
    const elementsFromMatDialogContainer: HTMLElement[] = [];
    const elementsNotFromMatDialogContainer: HTMLElement[] = [];
    const invalidElements: NodeListOf<HTMLElement> = document.querySelectorAll(query);
    forEach(invalidElements, (element: HTMLElement) => {
      if (this.isChildOfOrMatDialogContainer(element)) {
        elementsFromMatDialogContainer.push(element);
      } else {
        elementsNotFromMatDialogContainer.push(element);
      }
    });

    const matDialogs: NodeListOf<HTMLElement> = document.querySelectorAll('mat-dialog-container');
    if (size(matDialogs) > 0 && size(elementsFromMatDialogContainer) < 1) {
      return [];
    }

    return size(elementsFromMatDialogContainer) > 0 ? elementsFromMatDialogContainer : elementsNotFromMatDialogContainer;
  }

  private isChildOfOrMatDialogContainer(element: HTMLElement): boolean {
    const elementIsMatDialogContainer = StringUtils.isEqualIgnoreCase('mat-dialog-container', element.nodeName);
    if (elementIsMatDialogContainer) {
      return true;
    }
    return isNil(element.parentElement) ? false : this.isChildOfOrMatDialogContainer(element.parentElement);
  }

  private findFirstFormElement(elements: HTMLElement[]): HTMLElement {
    let result: HTMLElement = first(elements);

    const matchingElement: HTMLElement = find(elements, (element: HTMLElement) => {
      const elementName: string = element.nodeName.toLowerCase();
      return includes(FormValidationHelper.allowedElements, elementName);
    });

    if (!isNil(matchingElement)) {
      result = matchingElement;
    }

    return result;
  }

  private openParentExtensionPanel(element: HTMLElement): void {
    const elementIsExpansionPanel = StringUtils.isEqualIgnoreCase(element.nodeName, 'mat-expansion-panel');
    if (elementIsExpansionPanel) {
      const firstElement: HTMLElement = <HTMLElement>find(element.children, (child: HTMLElement) => {
        const isExpansionHeader = StringUtils.isEqualIgnoreCase(child.nodeName, 'mat-expansion-panel-header');
        const isExpanded = child.className.indexOf('mat-expanded') >= 0;
        return isExpansionHeader && !isExpanded;
      });
      if (!isNil(firstElement)) {
        firstElement.click();
      }
    }

    if (!isNil(element.parentElement)) {
      this.openParentExtensionPanel(element.parentElement);
    }
  }

  private selectParentTab(element: HTMLElement): void {
    const elementIsTabPanel = element.classList.contains('mat-tab-label');
    if (elementIsTabPanel) {
      element.click();
    }

    if (!isNil(element.parentElement)) {
      this.selectParentTab(element.parentElement);
    }
  }

  private checkIfElementIsContainedInTabBody(element: HTMLElement): void {
    const elementIsTabBody = StringUtils.isEqualIgnoreCase('mat-tab-body', element.nodeName);
    if (elementIsTabBody) {
      const tabName: string = element.getAttribute('aria-labelledby');
      this.selectMatTabGroup(element, tabName);
    }

    if (!isNil(element.parentElement)) {
      this.checkIfElementIsContainedInTabBody(element.parentElement);
    }
  }

  private selectMatTabGroup(element: HTMLElement, tabName: string): void {
    const elementIsMatTabGroup = StringUtils.isEqualIgnoreCase('mat-tab-group', element.nodeName);

    if (elementIsMatTabGroup) {
      this.selectMatTabHeader(element, tabName);
    }

    if (!isNil(element.parentElement)) {
      this.selectMatTabGroup(element.parentElement, tabName);
    }
  }

  private selectMatTabHeader(element: HTMLElement, tabName: string): void {
    const tabHeadersElements: HTMLCollectionOf<Element> = element.getElementsByClassName('mat-tab-label');
    const tabElement: HTMLElement = <HTMLElement>find(tabHeadersElements, (tab: HTMLElement) => isEqual(tabName, tab.getAttribute('aria-controls')));

    if (!isNil(tabElement)) {
      tabElement.click();
    }
  }

  private scrollToFirstInvalidFormElement(invalidElements: HTMLElement[]): void {
    const element: HTMLElement = this.findFirstFormElement(invalidElements);
    this.openParentExtensionPanel(element);
    this.selectParentTab(element);
    this.checkIfElementIsContainedInTabBody(element);
    setTimeout(() => element.parentElement.scrollIntoView(), 400);
  }
}
