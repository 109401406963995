export enum PaymentStatus {
  PENDING_PAYMENT = 1,
  PAYING,
  PAID,
  CANCELLED
}

export const PAYMENT_ADD_DETAIL_STATUSES = [PaymentStatus.PENDING_PAYMENT, PaymentStatus.PAYING];

export const PAYMENT_CANCELLATION_STATUSES = [PaymentStatus.PENDING_PAYMENT, PaymentStatus.CANCELLED];
