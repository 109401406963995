import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TranslateService} from '@application/translation/translate.service';
import {EnhancementControlService} from '@shared/services/enhancement-control.service';
import {each, filter, isEmpty, isNil} from 'lodash-es';
import {takeUntil} from 'rxjs/operators';
import {BaseComponent} from '../../base-component';
import {RepositionDialogComponent} from '../reposition-dialog/reposition-dialog.component';
import {AdvancedDateFilterDialogComponent} from './advanced-date-filter-dialog/advanced-date-filter-dialog.component';
import {AdvancedSearchFilterDialogComponent} from './advanced-search-filter-dialog/advanced-search-filter-dialog.component';
import {IAdvancedSearchOptionFilterTypeEnum} from './advanced-search-option-filter/advanced-search-option-filter.enum';
import {IAdvancedSearchOptionFilter} from './advanced-search-option-filter/advanced-search-option-filter.option';
import {AdvancedDateFilter} from './advanced-search/advanced-date-filter';
import {AdvancedSearchFilter, IAdvancedSearchFilter} from './advanced-search/advanced-search-filter';
import {SelectFilterOption} from './advanced-search/select-filter-option';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent extends BaseComponent implements OnInit {
  @ViewChild('filterOptions') public filterOptions: ElementRef;

  @Input() public advancedSearchFilters: IAdvancedSearchFilter[] = [];
  @Input() public advancedSearchOptionFilters: IAdvancedSearchOptionFilter[] = [];
  @Input() public propertyNameList: Map<string, string>;
  @Output() public searchFiltersChanged: EventEmitter<void> = new EventEmitter<void>();

  public showGeneralFilter: boolean;
  public advancedSearchOptionFilterTypeEnum = IAdvancedSearchOptionFilterTypeEnum;

  private dialog: MatDialog;
  private elementRef: ElementRef;
  private readonly translateService: TranslateService;
  private readonly dialogConfigForAdvancedSearch: MatDialogConfig = <MatDialogConfig>{
    autoFocus: false,
    panelClass: 'reposition-dialog',
    backdropClass: 'cdk-overlay-transparent-backdrop',
    width: '239px',
    maxWidth: '100%',
    maxHeight: '100%'
  };

  public constructor(translate: TranslateService, dialog: MatDialog, elementRef: ElementRef, private enhancementControlService: EnhancementControlService) {
    super();
    this.translateService = translate;
    this.dialog = dialog;
    this.elementRef = elementRef;
    this.showGeneralFilter = !enhancementControlService.isPaginationEnabled;
  }

  ngOnInit(): void {
    this.showGeneralFilter = !this.enhancementControlService.isPaginationEnabled;
  }

  public onSelectionChange(value: any, propertyName: string): void {
    const searchFilter = this.advancedSearchOptionFilters.find((s: IAdvancedSearchOptionFilter) => s.propertyName === propertyName);
    if (searchFilter.filterType == this.advancedSearchOptionFilterTypeEnum.RADIO_BUTTON) {
      searchFilter.selectedValue = value;
    }

    this.searchFiltersChanged.emit();
  }

  public isFilterSelected(): boolean {
    const advancedSearchFiltersChanged = this.advancedSearchFilters.some((filter) => {
      if (filter instanceof AdvancedSearchFilter) {
        return filter.selectedValues?.length < filter.propertyOptions.length;
      }
      if (filter instanceof AdvancedDateFilter) {
        return filter.selectedValues.length > 0;
      }
    });

    const advancedSearchOptionFiltersChanged = this.advancedSearchOptionFilters.some((filter) => {
      if (filter.filterType === this.advancedSearchOptionFilterTypeEnum.RADIO_BUTTON) {
        return filter.selectedValue.value !== filter.defaultSelectedValue.value;
      }
    });

    return advancedSearchFiltersChanged || advancedSearchOptionFiltersChanged;
  }

  public showArrowDownIcon(filter: any): boolean {
    return filter instanceof AdvancedSearchFilter;
  }

  public isAdvancedSearchFiltersSelected(): boolean {
    return !isEmpty(filter(this.advancedSearchFilters, ['isSelected', true]));
  }

  public openOptionshDialog(): void {
    const searchFilters = this.advancedSearchFilters.map((advancedSearchFilter: IAdvancedSearchFilter) => new SelectFilterOption(advancedSearchFilter.propertyName, advancedSearchFilter.title));

    this.showDialog(
      {
        component: AdvancedSearchFilterDialogComponent,
        sourceElement: this.filterOptions.nativeElement,
        advancedSearchFilter: new AdvancedSearchFilter(
          'advancedSearchFilter',
          searchFilters,
          this.translateService.instant('GENERAL.ADVANCED_SEARCH.FILTERS'),
          this.advancedSearchFilters.filter((s: IAdvancedSearchFilter) => s.isSelected).map((s: IAdvancedSearchFilter) => s.propertyName)
        )
      },
      (result: {selectedValues: any[]}) => {
        if (!isNil(result?.selectedValues)) {
          each(this.advancedSearchFilters, (searchFilter: IAdvancedSearchFilter) => {
            searchFilter.isSelected = result.selectedValues.includes(searchFilter.propertyName);
            if (isEmpty(searchFilter.selectedValues)) {
              searchFilter.setDefaultValues();
            }
          });
          this.searchFiltersChanged.emit();
        }
      }
    );
  }

  public openFilterDialog(propertyName: string): void {
    const searchFilter = this.advancedSearchFilters.find((s: IAdvancedSearchFilter) => s.propertyName === propertyName);
    if (searchFilter instanceof AdvancedSearchFilter) {
      this.showDialog(
        {
          component: AdvancedSearchFilterDialogComponent,
          sourceElement: this.elementRef.nativeElement.querySelector(`#${searchFilter.propertyName}`),
          advancedSearchFilter: searchFilter
        },
        (result: {selectedValues: any[]}) => {
          if (!isNil(result?.selectedValues)) {
            searchFilter.selectedValues = result.selectedValues;
            searchFilter.summary = this.getSummary(searchFilter.selectedValues, searchFilter.propertyOptions);
            this.searchFiltersChanged.emit();
          }
        }
      );
    } else if (searchFilter instanceof AdvancedDateFilter) {
      this.showDialog(
        {
          component: AdvancedDateFilterDialogComponent,
          sourceElement: this.elementRef.nativeElement.querySelector(`#${searchFilter.propertyName}`),
          advancedSearchFilter: searchFilter
        },
        (result: {selectedValues: any[]}) => {
          if (!isNil(result?.selectedValues)) {
            searchFilter.selectedValues = result.selectedValues;
            this.searchFiltersChanged.emit();
          }
        }
      );
    }
  }

  public clearFilter(event: any, propertyName: string): void {
    event.preventDefault();
    event.stopPropagation();

    const searchFilter = this.advancedSearchFilters.find((s: IAdvancedSearchFilter) => s.propertyName === propertyName);
    searchFilter.isSelected = false;
    searchFilter.selectedValues = [];
    this.searchFiltersChanged.emit();
  }

  public clearAllFilters(): void {
    each(this.advancedSearchFilters, (searchFilter: IAdvancedSearchFilter) => {
      if (this.showGeneralFilter) {
        searchFilter.isSelected = false;
        searchFilter.selectedValues = [];
      } else {
        searchFilter.setDefaultValues();
      }
    });

    each(this.advancedSearchOptionFilters, (filter: IAdvancedSearchOptionFilter) => {
      if (filter.filterType == this.advancedSearchOptionFilterTypeEnum.RADIO_BUTTON) {
        filter.selectedValue = filter.defaultSelectedValue;
      }
    });

    this.searchFiltersChanged.emit();
  }

  private showDialog(dialogData: any, functionDef: (result: any) => void = null): void {
    const dialogConfig: MatDialogConfig = this.dialogConfigForAdvancedSearch;
    dialogConfig.data = dialogData;
    this.dialog.open(RepositionDialogComponent, dialogConfig).afterClosed().pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe(functionDef?.bind(this));
  }

  private getSummary(selectedValues: any[], propertyOptions: any[]): string {
    return selectedValues.length === propertyOptions.length ? this.translateService.instant('GENERAL.ADVANCED_SEARCH.ALL') : !isEmpty(selectedValues) ? `+${selectedValues.length}` : '';
  }
}
