import {RouteBranch} from '../route-branch';
import {OrderDeliveryFormRoute} from './order-delivery-form-route';
import {OrderDeliveryRecordRoute} from './order-delivery-record-route';

export class OrderDeliveryRoute extends RouteBranch {
  private _orderDeliveryRecordRoute: OrderDeliveryRecordRoute;
  private _orderDeliveryFormRoute: OrderDeliveryFormRoute;

  public get orderDeliveryRecordRoute(): OrderDeliveryRecordRoute {
    return this._orderDeliveryRecordRoute;
  }

  public set orderDeliveryRecordRoute(value: OrderDeliveryRecordRoute) {
    this.replace(this._orderDeliveryRecordRoute, value);
    this._orderDeliveryRecordRoute = value;
  }

  public get orderDeliveryFormRoute(): OrderDeliveryFormRoute {
    return this._orderDeliveryFormRoute;
  }

  public set orderDeliveryFormRoute(value: OrderDeliveryFormRoute) {
    this.replace(this._orderDeliveryFormRoute, value);
    this._orderDeliveryFormRoute = value;
  }
}
