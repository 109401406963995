export enum DeliveryFormStatus {
  PENDING_AX_ORDER = 1,
  PENDING_SHIPMENT = 2,
  SHIPPING = 3,
  DELIVERED = 4,
  COMPLETED = 5,
  LATE_SHIPMENT = 6,
  NO_SHIPMENT = 7
}

export const ENABLE_EDIT_ADDRESS = [DeliveryFormStatus.PENDING_SHIPMENT, DeliveryFormStatus.LATE_SHIPMENT];
export const DELIVERED_DELIVERY_FORM_STATUS = [DeliveryFormStatus.SHIPPING, DeliveryFormStatus.DELIVERED, DeliveryFormStatus.COMPLETED];
