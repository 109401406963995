<mat-card fxLayout="column" class="tabs-menu-card" style="background-color: transparent; border: none">
  <mat-card-header fxLayout="row" fxLayoutAlign="start center">
    <div class="tab-bar-container">
      <div mat-tab-nav-bar fxFlex>
        <ng-container *ngFor="let menuItem of menuItems">
          <a mat-tab-link (click)="selectMenuItem(menuItem)" [active]="selectedMenuItem === menuItem">{{ menuItem.translationKey | translate }}</a>
        </ng-container>
      </div>
      <div *ngIf="!canShowAdditionalTabs" class="overflow-hidden" fxLayout="row" fxLayoutAlign="end center" fxFlex="20">
        <button #selectButton type="button" mat-button class="select-button" (click)="tabSelect.open()">
          {{ 'SETTINGS.ALL' | translate }} ({{ menuItems.length }})
          <mat-select #tabSelect disableOptionCentering (opened)="toggleSelectButton()" (closed)="toggleSelectButton()" [(value)]="selectedMenuItem.value">
            <mat-option *ngFor="let menuItem of menuItems" (click)="selectMenuItem(menuItem)" [value]="menuItem.value"> {{ menuItem.translationKey | translate }} </mat-option>
          </mat-select>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="content" fxLayout="column">
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-footer *ngIf="isDeleting" style="margin-bottom: 16px !important" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <ng-container>
      <button *ngIf="!processing" class="vdw-button vdw-secondary-button" (click)="cancelDelete()">{{ 'GENERAL.ACTIONS.CANCEL' | translate }}</button>
      <app-progress-button [disabled]="processing || !isRowSelected" [inProgress]="processing" (progressClick)="delete()" class="progress-button" label="GENERAL.ACTIONS.DELETE"></app-progress-button>
    </ng-container>
  </mat-card-footer>
</mat-card>
