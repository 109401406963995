/**
 * Workaround to be able to catch the instance of the error
 * https://github.com/Microsoft/TypeScript/issues/13965#issuecomment-278570200
 */

export class BaseError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
