import {isNil} from 'lodash-es';
import moment from 'moment';

export class TimezoneUtils {
  public static convertToDateWithCurrentOffset(dateTimeString: string, timezoneCode: string): Date {
    let date: Date = null;

    if (!isNil(dateTimeString)) {
      const momentDate = moment(dateTimeString);
      const timezoneOffsetInMinutes = momentDate.clone().tz(timezoneCode).utcOffset();
      date = momentDate.add(timezoneOffsetInMinutes - momentDate.utcOffset(), 'minutes').toDate();
    }

    return date;
  }

  public static convertToISOStringWithoutCurrentOffset(date: Date, timezoneCode: string): string {
    const momentDate = moment(date);
    const timezoneOffsetInMinutes = momentDate.clone().tz(timezoneCode).utcOffset();

    return momentDate.subtract(timezoneOffsetInMinutes - momentDate.utcOffset(), 'minutes').toISOString();
  }

  public static convertToDateWithTimeZoneOffset(dateTimeString: Date): Date {
    return moment(dateTimeString).add(this.getTimeZoneOffsetInMinutes(), 'minutes').toDate();
  }

  public static convertTimeZoneOffset(dateTimeString: Date): any {
    return moment(dateTimeString).add(this.getTimeZoneOffsetInMinutes(), 'minutes');
  }

  private static getTimeZoneOffsetInMinutes(): number {
    return Math.abs(new Date().getTimezoneOffset());
  }
}
