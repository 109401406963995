import {Pipe, PipeTransform} from '@angular/core';
import {isNil} from 'lodash-es';
import moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
  public transform(value: Date, format: string): string {
    let result = '';

    if (!isNil(value)) {
      result = moment(value).format(format);
    }

    return result;
  }
}
