import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class OverlayActionsService {
  public actionTriggeredEmitter = new EventEmitter<string>();

  public notifyActionTriggered(actionKey: string): void {
    this.actionTriggeredEmitter.next(actionKey);
  }
}
