import {Component} from '@angular/core';
import {TranslateService} from '@application/translation/translate.service';

@Component({
  selector: 'app-loading-file',
  templateUrl: './loading-file.component.html',
  styleUrls: ['./loading-file.component.scss']
})
export class LoadingFileComponent {
  private translate: TranslateService;

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public getTitle(): string {
    return this.translate.instant('OVERLAY.LOADING.TITLE', null, this.translate.getLocale().language);
  }

  public getText(): string {
    return this.translate.instant('OVERLAY.LOADING.DOWNLOAD_DESCRIPTION', null, this.translate.getLocale().language);
  }
}
