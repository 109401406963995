import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TranslateService} from '@application/translation/translate.service';
import {AlertDialogData, AlertDialogResult} from '@presentation/components/alert-dialog/alert-dialog-data';
import {AlertDialogComponent} from '@presentation/components/alert-dialog/alert-dialog.component';
import {isNil} from 'lodash-es';

export class DialogHelper {
  public static showDialogWithFunction(dialog: MatDialog, dialogConfig: MatDialogConfig, alertDialogData: AlertDialogData, functionDef: (result: AlertDialogResult) => void = null): void {
    dialogConfig.data = alertDialogData;

    dialog.open(AlertDialogComponent, dialogConfig).afterClosed().subscribe(functionDef.bind(this));
  }

  public static showSubmittedSuccessDialog(
    dialog: MatDialog,
    dialogConfig: MatDialogConfig,
    translateService: TranslateService,
    object: string,
    description: string,
    functionDef: (result: AlertDialogResult) => void = null
  ): void {
    const alertDialogData = AlertDialogData.createSuccessData(
      DialogHelper.getTranslationDialog(translateService, 'GENERAL.ACTIONS.SUBMITTED_OBJECT', object),
      DialogHelper.getTranslationDialog(translateService, description)
    );

    this.showDialogWithFunction(dialog, dialogConfig, alertDialogData, functionDef);
  }

  public static showSubmittedFailDialog(
    dialog: MatDialog,
    dialogConfig: MatDialogConfig,
    translateService: TranslateService,
    actions: string,
    object: string,
    errorMessage: string,
    functionDef: (result: AlertDialogResult) => void = null,
    description: any = null
  ): void {
    const alertDialogData = AlertDialogData.createErrorData(
      DialogHelper.getTranslationDialog(translateService, actions, object),
      description ?? DialogHelper.getTranslationDialog(translateService, errorMessage)
    );

    this.showDialogWithFunction(dialog, dialogConfig, alertDialogData, functionDef);
  }

  public static showConfirmDialog(
    dialog: MatDialog,
    dialogConfig: MatDialogConfig,
    translateService: TranslateService,
    description: string,
    object: string,
    module: string,
    functionDef: (result: AlertDialogResult) => void = null,
    customAlert: string = null
  ): void {
    const alertDialogData = AlertDialogData.createConfirmationData(
      DialogHelper.getTranslationDialog(translateService, 'GENERAL.ACTIONS.CONFIRM_OBJECT', object),
      DialogHelper.getTranslationDialog(translateService, description, object, module),
      customAlert
    );
    this.showDialogWithFunction(dialog, dialogConfig, alertDialogData, functionDef);
  }

  public static showDeleteDialog(
    dialog: MatDialog,
    dialogConfig: MatDialogConfig,
    translateService: TranslateService,
    description: string,
    functionDef: (result: AlertDialogResult) => void = null
  ): void {
    const alertDialogData = AlertDialogData.createDeleteData(
      translateService.instant('GENERAL.ACTIONS.DELETE_OBJECT', {object: translateService.instant('GENERAL.DATA')}),
      DialogHelper.getTranslationDialog(translateService, description)
    );

    this.showDialogWithFunction(dialog, dialogConfig, alertDialogData, functionDef);
  }

  public static showErrorDialog(dialog: MatDialog, dialogConfig: MatDialogConfig, title: string, errorMessage: string, functionDef: (result: AlertDialogResult) => void = null): void {
    const alertDialogData = AlertDialogData.createErrorData(title, errorMessage);

    this.showDialogWithFunction(dialog, dialogConfig, alertDialogData, functionDef);
  }

  public static showUnableEditDialog(dialog: MatDialog, dialogConfig: MatDialogConfig, translateService: TranslateService, object: string = null, module: string = null, status: string = null): void {
    dialogConfig.data = AlertDialogData.createInformativeData(
      translateService.instant('GENERAL.ACTIONS.UNABLE_EDIT'),
      this.getTranslationDialog(translateService, 'GENERAL.ACTIONS.UNABLE_EDIT_DESCRIPTION', object, module, status)
    );

    dialog.open(AlertDialogComponent, dialogConfig);
  }

  public static getTranslationDialog(translateService: TranslateService, title: string, object: string = null, module: string = null, status: string = null): string {
    const params = isNil(object) ? null : {object: translateService.instant(object)};
    if (!isNil(object) && !isNil(module)) {
      params['module'] = translateService.instant(module);
    }

    if (!isNil(status)) {
      params['status'] = translateService.instant(status);
    }

    return translateService.instant(title, params);
  }
}
