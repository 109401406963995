export enum RegistrationStatus {
  PENDING_INPUT = 1,
  PENDING_APPROVAL = 2,
  REVERIFY = 3,
  APPROVED = 4,
  REJECTED = 5,
  END_MANUAL_ADD = 9
}

export const REGISTRATION_VERIFIED_STATUSES = [RegistrationStatus.APPROVED, RegistrationStatus.REJECTED, RegistrationStatus.REVERIFY];
export const REGISTRATION_EDITABLE_STATUSES = [RegistrationStatus.PENDING_INPUT, RegistrationStatus.REVERIFY, RegistrationStatus.REJECTED, RegistrationStatus.END_MANUAL_ADD];
