import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfiguration} from '@application/configuration/application-config';
import {map} from 'lodash-es';
import {Observable} from 'rxjs';
import {map as rxjsMap} from 'rxjs/operators';
import {IHttpNotificationService} from './http-notification.interface';
import {MarkAsReadNotificationRequest} from './notification-mark-as-read.request';
import {NotificationOverviewResponse} from './notification-overview.response';

@Injectable({
  providedIn: 'root'
})
export class HttpNotificationService implements IHttpNotificationService {
  private _httpClient: HttpClient;
  private _appConfig: AppConfiguration;
  private _url: string;

  public constructor(httpClient: HttpClient, appConfig: AppConfiguration) {
    this._httpClient = httpClient;
    this._appConfig = appConfig;
    this._url = `${this._appConfig.messagesEndpoint()}notifications`;
  }

  public getAll(): Observable<NotificationOverviewResponse[]> {
    return this._httpClient.get(`${this._url}`).pipe(rxjsMap((responseJSON: any) => map(responseJSON, (json: any) => NotificationOverviewResponse.fromJSON(json))));
  }

  public getPaginated(startRow: number, endRow: number, column: string, sort: string): Observable<any> {
    return this._httpClient
      .get<NotificationOverviewResponse[]>(`${this._url}/paginated?startRow=${startRow}&endRow=${endRow}&orderBy=${column} ${sort}`, {
        observe: 'response'
      })
      .pipe(
        rxjsMap((res: any) => {
          const payload: NotificationOverviewResponse[] = res.body;
          const {totalCount} = JSON.parse(res.headers.get('x-pagination'));
          const {totalUnread} = JSON.parse(res.headers.get('x-pagination'));

          const output: [any[], any, any] = [payload, totalCount, totalUnread];
          return output;
        })
      );
  }

  public markAsRead(request: number[]): Observable<any> {
    let markAsReadNotificationRequest = new MarkAsReadNotificationRequest();
    markAsReadNotificationRequest.notificationIds = request;

    return this._httpClient.patch<void>(`${this._url}/mark-as-read`, markAsReadNotificationRequest.toJSON()).pipe(
      rxjsMap((response: any) => {
        return response;
      })
    );
  }
}
