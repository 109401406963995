import {IdName} from '@infrastructure/id-name';
import {isNil} from 'lodash-es';

export class RegistrationContactPerson {
  public readonly name: string;
  public readonly phoneNumber: string;
  public readonly email: string;
  public readonly position?: IdName;

  public constructor(data: any = null) {
    Object.assign(this, data);
    this.position = !isNil(data.positionId) ? new IdName(data.positionId, data.position) : null;
  }

  public static fromJSON(data: any): RegistrationContactPerson {
    return !isNil(data) ? new RegistrationContactPerson(data) : null;
  }

  public toJSON(): JSON {
    return {
      name: this.name,
      phoneNumber: this.phoneNumber,
      email: this.email,
      positionId: this.position?.id,
      position: this.position?.name
    } as any as JSON;
  }
}
