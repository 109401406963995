export enum Permission {
  VIEW = 1,
  ADD,
  EDIT,
  DELETE,
  EXPORT,
  VERIFY,
  EDIT_AX_QUANTITY,
  INCLUDE_OEM_CUSTOMER,
  DELIVER_PARCEL,
  SYNC,
  DOWNLOAD
}
