import {ContactType} from '@domain/customer/contact-type.enum';

export class Contact {
  private _contactType: ContactType;
  private _contact: string;

  public constructor(contactType: ContactType, contact: string) {
    this._contactType = contactType;
    this._contact = contact;
  }

  public get contactType(): ContactType {
    return this._contactType;
  }

  public set contactType(contactType: ContactType) {
    this._contactType = contactType;
  }

  public get contact(): string {
    return this._contact;
  }

  public static fromJSON(data: any): Contact {
    return new Contact(data.contactType.id, data.contact);
  }

  public toJSON(): JSON {
    return {
      contactTypeId: Number(this._contactType),
      contact: this._contact
    } as any as JSON;
  }
}
