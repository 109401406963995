<perfect-scrollbar class="side-nav" fxLayout="column">
  <mat-list fxLayout="column" fxLayoutAlign="start" fxLayoutAlign.gt-xs="start center" fxFlex="noshrink">
    <ul
      class="ant-menu ant-menu-root ant-menu-inline side-nav-menu nav-menu"
      [ngClass]="{
        'ant-menu-inline-collapsed': isCollapsed,
        'ant-menu-dark': isSideNavDark
      }"
    >
      <li
        *ngFor="let item of menuItems"
        class="ant-menu-submenu ant-menu-submenu-inline nav-menu-item"
        fxLayout="column"
        fxLayoutAlign="center center"
        [matTooltip]="item.name | translate"
        [matTooltipDisabled]="!isCollapsed || item.subItems.length > 0"
        [routerLinkActive]="item.path !== '' && !item.subItems.length ? 'ant-menu-item-selected' : ''"
        [routerLinkActiveOptions]="{exact: true}"
      >
        <a [id]="item.id" class="ant-menu-submenu-title" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" [routerLink]="item.path" (click)="selectMenu(item.id)">
          <mat-icon fxFlex="40px" [svgIcon]="item.icon"></mat-icon>
          <span *ngIf="!isCollapsed" fxFlex>{{ item.name | translate }}</span>
          <mat-icon *ngIf="!isCollapsed && item.subItems.length > 0" svgIcon="side-arrow-right-small" class="arrow" fxLayoutAlign="end center" fxFlex="24px"></mat-icon>
        </a>

        <!-- Second Level -->
        <ul class="ant-menu ant-menu-inline ant-menu-sub nav-submenu" *ngIf="item.subItems.length > 0">
          <li *ngIf="isCollapsed" class="nav-submenu-item font-weight-bold">
            <span>{{ item.name | translate }}</span>
          </li>
          <li *ngFor="let subItem of item.subItems" class="ant-menu-submenu ant-menu-submenu-inline nav-submenu-item" routerLinkActive="ant-menu-item-selected">
            <a [id]="subItem.id" class="ant-menu-submenu-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" [routerLink]="subItem.path">
              <mat-icon *ngIf="!isCollapsed" fxFlex="40px" [svgIcon]=""></mat-icon>
              <span fxFlex>{{ subItem.name | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </mat-list>

  <mat-list fxLayoutAlign="end" fxLayoutAlign.gt-xs="end center">
    <ul
      class="ant-menu ant-menu-root ant-menu-inline side-nav-menu"
      [ngClass]="{
        'ant-menu-inline-collapsed': isCollapsed,
        'ant-menu-dark': isSideNavDark
      }"
    >
      <li class="ant-menu-submenu ant-menu-submenu-inline" routerLinkActive="ant-menu-item-selected" [routerLinkActiveOptions]="{exact: true}">
        <a class="ant-menu-submenu-title" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" (click)="toggleFold()">
          <mat-icon fxFlex="40px" [svgIcon]="isCollapsed ? 'side-arrow-right' : 'side-arrow-left'"></mat-icon>
          <span *ngIf="!isCollapsed" fxFlex>{{ 'GENERAL.ACTIONS.CLOSE_SIDEBAR' | translate }}</span>
        </a>
      </li>
    </ul>
  </mat-list>
</perfect-scrollbar>
