import {isEmpty} from 'lodash-es';
import moment from 'moment';
import {IAdvancedSearchFilter} from './advanced-search-filter';

export enum DateFilterType {
  DATE_RANGE = 1
}

export class AdvancedDateFilter implements IAdvancedSearchFilter {
  private _propertyName: string;
  private _propertyValues: DateFilterType[];
  private _title: string;
  private _selectedValues?: any[];
  private _isSelected: boolean;

  public constructor(propertyName: string, propertyValues: DateFilterType[], title: string, selectedValues: any[] = [], isSelected: boolean = false) {
    this._propertyName = propertyName;
    this._propertyValues = propertyValues;
    this._title = title;
    this._selectedValues = selectedValues;
    this._isSelected = isSelected;
  }

  public get propertyName(): string {
    return this._propertyName;
  }

  public get propertyOptions(): any[] {
    return this._propertyValues;
  }

  public get title(): string {
    return this._title;
  }

  public get selectedValues(): {filterType: DateFilterType; value: any}[] {
    return this._selectedValues;
  }

  public set selectedValues(selectedValues: {filterType: DateFilterType; value: any}[]) {
    this._selectedValues = selectedValues;
  }

  public get isSelected(): boolean {
    return this._isSelected;
  }

  public set isSelected(isSelected: boolean) {
    this._isSelected = isSelected;
  }

  public get summary(): string {
    return this._isSelected && !isEmpty(this.selectedValues) ? '+1' : null;
  }

  public static getDefaultDateRange(): {start: Date; end: Date} {
    const currentDate = moment();
    return {
      start: currentDate.startOf('day').toDate(),
      end: currentDate.endOf('day').toDate()
    };
  }

  public setDefaultValues(): void {
    this._selectedValues = [];
  }
}
