import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_SELECT_CONFIG} from '@angular/material/select';
import {BaseComponent} from '@presentation/base-component';
import {isEqual} from 'lodash-es';
import {Observable} from 'rxjs';
import {Subscription} from 'rxjs/internal/Subscription';
import {TabsMenuItem} from './tabs-menu-item';

@Component({
  selector: 'app-tabs-menu-card',
  templateUrl: './tabs-menu-card.component.html',
  styleUrls: ['./tabs-menu-card.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: {overlayPanelClass: 'button-select-overlay-panel'}
    }
  ]
})
export class TabsMenuCardComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('selectButton', {read: ElementRef}) selectButtonComponent: ElementRef;
  @ViewChild('tabSelect', {read: ElementRef}) tabSelectComponent: ElementRef;

  @Input() public menuItems: TabsMenuItem[];
  @Input() public selectedMenuItem: TabsMenuItem;
  @Input() public canShowAdditionalTabs = false;
  @Input() rowSelected: Observable<boolean>;
  @Input() deleteButtonClicked: Observable<boolean>;
  @Input() deleteProcessing: Observable<boolean>;
  @Output() public selectedMenuItemChange: EventEmitter<TabsMenuItem> = new EventEmitter<TabsMenuItem>();
  @Output() public isDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isDeleting = false;
  public isRowSelected = false;
  private subscription: Subscription;

  public ngOnInit(): void {
    this.subscription = this.deleteButtonClicked.subscribe((isDeleting: boolean) => (this.isDeleting = isDeleting));
    this.subscription = this.deleteProcessing.subscribe((isProcessing: boolean) => (this.processing = isProcessing));
    this.subscription = this.rowSelected.subscribe((isRowSelected: boolean) => (this.isRowSelected = isRowSelected));
  }

  public selectMenuItem(menuItem: TabsMenuItem): void {
    this.selectedMenuItemChange.emit(menuItem);
  }

  public isMenuItemSelected(menuItem: TabsMenuItem): boolean {
    return isEqual(menuItem.value, this.selectedMenuItem.value);
  }

  public toggleSelectButton(): void {
    const selectButtonEl = this.selectButtonComponent.nativeElement as Element;
    const tabSelectEl = this.tabSelectComponent.nativeElement as Element;

    if (tabSelectEl.getAttribute('aria-expanded') === 'true') {
      selectButtonEl.classList.add('active');
    } else {
      selectButtonEl.classList.remove('active');
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public cancelDelete(): void {
    this.isDeleting = false;
    this.isDelete.emit(false);
  }

  public delete(): void {
    this.isDelete.emit(true);
  }
}
