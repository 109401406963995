import {AfterContentChecked, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {LanguageService} from '@application/translation/language/language.service';
import {TranslateService} from '@application/translation/translate.service';
import {BaseComponent} from '@presentation/base-component';
import {DatepickerHeaderComponent} from '@presentation/components/datepicker-header/datepicker-header.component';
import {RepositionDialogComponent} from '@presentation/components/reposition-dialog/reposition-dialog.component';
import {find, isNil} from 'lodash-es';
import moment from 'moment';
import {AdvancedDateFilter, DateFilterType} from '../advanced-search/advanced-date-filter';
import {IAdvancedSearchFilter} from '../advanced-search/advanced-search-filter';

@Component({
  templateUrl: './advanced-date-filter-dialog.component.html',
  styleUrls: ['./advanced-date-filter-dialog.component.scss']
})
export class AdvancedDateFilterDialogComponent extends BaseComponent implements OnInit, AfterContentChecked {
  public advancedDateFilter: IAdvancedSearchFilter;
  public dateRangeForm: FormGroup;
  public datepickerHeader = DatepickerHeaderComponent;

  private readonly translateService: TranslateService;
  private readonly languageService: LanguageService;
  private readonly formBuilder: FormBuilder;
  private readonly dialogRef: MatDialogRef<RepositionDialogComponent>;
  private readonly changeDetectorRef: ChangeDetectorRef;
  private _adapter: DateAdapter<any>;

  public constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    formBuilder: FormBuilder,
    dialogRef: MatDialogRef<RepositionDialogComponent>,
    changeDetectorRef: ChangeDetectorRef,
    languageService: LanguageService,
    translateService: TranslateService,
    adapter: DateAdapter<any>
  ) {
    super();
    this.advancedDateFilter = data.advancedSearchFilter;
    this._adapter = adapter;
    this.formBuilder = formBuilder;
    this.languageService = languageService;
    this.translateService = translateService;
    this.dialogRef = dialogRef;
    this.changeDetectorRef = changeDetectorRef;
  }

  public ngOnInit(): void {
    this.setFormFields();
    this._adapter.setLocale(this.languageService.getActiveLanguage());
  }

  public ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public confirm(): void {
    const selectedValues = [
      {
        filterType: DateFilterType.DATE_RANGE,
        value: {start: this.dateRangeForm.controls.start.value.startOf('day'), end: this.dateRangeForm.controls.end.value.endOf('day')}
      }
    ];
    this.dialogRef.close({selectedValues});
  }

  private setFormFields(): void {
    let dateRange = find(this.advancedDateFilter.selectedValues, ['filterType', DateFilterType.DATE_RANGE])?.value;

    if (isNil(dateRange)) {
      dateRange = AdvancedDateFilter.getDefaultDateRange();
    }

    this.dateRangeForm = this.formBuilder.group({
      start: [moment(dateRange.start)],
      end: [moment(dateRange.end)]
    });
  }
}
