export class RefreshTokenResponse {
  public readonly accessToken: string | null;
  public readonly expiresIn: number | null;
  public readonly tokenType: string | null;
  public readonly refreshToken: string | null;

  public constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }

  public static fromJSON(data: any): RefreshTokenResponse {
    return new RefreshTokenResponse(data);
  }
}
