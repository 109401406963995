import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {regExps} from '@domain/regex-format';
import {EnhancementControlService} from '@shared/services/enhancement-control.service';
import {isEmpty, isEqual, isNil} from 'lodash-es';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {
  @Output() public filteredTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public filterIconClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Input() public placeholder = 'GENERAL.SEARCH';
  @Input() public icon = 'search';
  @Input() public disabled = false;
  @Input() public filteredText;
  @Input() public iconDisabled = false;
  @Input() public filterWidth = 'auto';
  @Input() public isAlphanumericValidation = false;
  @ViewChild('inputElement', {read: ElementRef}) public inputElement: ElementRef;
  @ViewChild('iconElement', {read: ElementRef}) public iconElement: ElementRef;

  private _debouncer: Subject<string> = new Subject<string>();
  private _enhancementControlService: EnhancementControlService;

  public constructor(enhancementControlService: EnhancementControlService) {
    this._enhancementControlService = enhancementControlService;
  }

  ngOnInit(): void {
    if (this._enhancementControlService.isPaginationEnabled) {
      this._debouncer.pipe(debounceTime(1000)).subscribe((value) => this.filteredTextChanged.emit(value));
    }
  }

  ngOnDestroy(): void {
    this._debouncer.unsubscribe();
  }

  public focus(): void {
    this.inputElement.nativeElement.focus();
  }

  public filterChanged(newValue: string): void {
    if (this.isAlphanumericValidation) {
      if (!regExps.alphanumericChinese.test(newValue)) {
        newValue = newValue.replace(regExps.invalidAlphanumericChinese, '');
        this.inputElement.nativeElement.value = newValue;

        if (isNil(newValue) || isEmpty(newValue)) {
          this.icon = 'cross';
        }
      }
    }

    if ((!isNil(newValue) || !isEmpty(newValue)) && isEqual(this.icon, 'search')) {
      this.icon = 'cross';
    } else if ((isNil(newValue) || isEmpty(newValue)) && isEqual(this.icon, 'cross')) {
      this.icon = 'search';
    }
    this.filteredText = newValue;
    if (this._enhancementControlService.isPaginationEnabled) {
      this._debouncer.next(this.filteredText);
    } else {
      this.filteredTextChanged.emit(this.filteredText);
    }
  }

  public iconClicked(event: MouseEvent): void {
    if (isEqual(this.icon, 'cross')) {
      this.filteredText = '';
      if (this._enhancementControlService.isPaginationEnabled) {
        this._debouncer.next(this.filteredText);
      } else {
        this.filteredTextChanged.emit(this.filteredText);
      }
      this.icon = 'search';
    } else {
      this.filterIconClicked.emit(event);
    }
  }

  public clear(): void {
    this.filterChanged('');
  }

  public isIconDisabled(): boolean {
    return this.iconDisabled;
  }
}
