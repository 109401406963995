import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {MessageToast} from './sa-message-toast';

@Component({
  selector: 'app-sa-toast',
  template: '<div class="sa-toast-wrapper" #toastWrapper></div>',
  styleUrls: ['./sa-message-toast.component.scss']
})
export class SaMessageToastComponent {
  @ViewChild('toastWrapper') toastWrapperEle: ElementRef;

  private _renderer: Renderer2;
  private _isVisible = false;

  public constructor(renderer: Renderer2) {
    this._renderer = renderer;
  }

  public showToast(toastInfo: MessageToast): void {
    this._isVisible = true;
    this.createToast(toastInfo.message, toastInfo.toastType);
  }

  public clearToast(): void {
    this._isVisible = false;
    if (this.toastWrapperEle !== undefined) {
      this._renderer.setProperty(this.toastWrapperEle.nativeElement, 'innerHTML', '');
    }
  }

  private createToast(message: string, toastType: string): void {
    const toastIcon = this._renderer.createElement('span');
    this._renderer.addClass(toastIcon, 'sa-icon');

    const toastContent = this._renderer.createElement('div');
    this._renderer.addClass(toastContent, 'sa-toast-content');
    this._renderer.setProperty(toastContent, 'innerHTML', message);

    const toast = this._renderer.createElement('div');
    this._renderer.setAttribute(toast, 'class', `sa-toast ${toastType}-toast`);
    this._renderer.appendChild(toast, toastIcon);
    this._renderer.appendChild(toast, toastContent);

    const toastWrapper = document.getElementsByClassName('sa-toast-wrapper');
    this._renderer.appendChild(toastWrapper[0], toast);
  }
}
