import {Component} from '@angular/core';
import {ITooltipAngularComp} from 'ag-grid-angular';
import {ITooltipParams} from 'ag-grid-community';
import {toString} from 'lodash-es';

@Component({
  selector: 'app-grid-tooltip',
  template: `<div class="custom-tooltip">
    <span>{{ getTooltip() }}</span>
  </div> `,
  styles: [
    `
      :host {
        position: absolute;
        background: rgba($primary-color-grey, 0.95);
        padding: 2px 8px 4px;
        border-radius: 4px;
        overflow: hidden;
        pointer-events: none;

        &.ag-tooltip-hiding {
          opacity: 0;
        }
      }

      .custom-tooltip span {
        white-space: nowrap;
        font-family: $mat-font-family;
        font-size: 10px;
        color: white;
      }
    `
  ]
})
export class GridTooltipComponent implements ITooltipAngularComp {
  private tooltip: string;

  public agInit(params: ITooltipParams): void {
    this.tooltip = toString(params.value);
  }

  public getTooltip(): string {
    return this.tooltip;
  }
}
