import {Inject, Injectable} from '@angular/core';
import {AppConfiguration} from '@application/configuration/application-config';
import {IModulePermission} from '@application/directives/has-permission.directive';
import {AUTHENTICATION, IAuthenticationService} from '@infrastructure/http/authentication/http-authentication.interface';
import {BaseComponent} from '@presentation/base-component';
import {LocalStorageService} from 'angular-2-local-storage';
import {isNil} from 'lodash-es';
import {Subject} from 'rxjs';
import {ModuleUtils} from '../module-utils';
import {Subscription} from './subscription';
import {ISubscriptionService} from './subscription.interface';

@Injectable({providedIn: 'root'})
export class SubscriptionService extends BaseComponent implements ISubscriptionService {
  protected currentSubscriptionSubject: Subject<Subscription> = new Subject<Subscription>();
  private readonly _storageConstant = 'subscription';

  public constructor(private _appConfig: AppConfiguration, private _localStorageService: LocalStorageService, @Inject(AUTHENTICATION) private _authenticationService: IAuthenticationService) {
    super();
  }

  public getCurrentSubscription(): Subscription {
    const storedSubscriptionJSONString: string = this._localStorageService.get(this._storageConstant);
    let result: Subscription = null;

    if (!isNil(storedSubscriptionJSONString)) {
      try {
        result = Subscription.fromLocalStorage(storedSubscriptionJSONString);
      } catch (e) {
        throw new Error('Invalid subscription in local storage');
      }
    }

    return result;
  }

  public setCurrentSubscription(subscription: Subscription): void {
    this.currentSubscriptionSubject.next(subscription);
    this._localStorageService.set(this._storageConstant, subscription.toLocalStorage());
  }

  public clearSubscription(): void {
    this.currentSubscriptionSubject.next(null);
    this._localStorageService.remove(this._storageConstant);
  }

  public hasPermission(modulePermission: IModulePermission): boolean {
    const currentSubscription = this.getCurrentSubscription();
    return (this._appConfig.isPreviewMode && ModuleUtils.isPreviewOnly(modulePermission.module)) || (!isNil(currentSubscription) && currentSubscription.hasPermission(modulePermission));
  }
}
