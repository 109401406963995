import {Injectable} from '@angular/core';
import {BackendErrorCodeEnum} from '@application/helper/backend-error-code.enum';
import {BackendErrorContext} from '@application/helper/backend-error-context';
import {Entity} from '@application/helper/entity.enum';
import {TranslateService} from '@application/translation/translate.service';
import {defaultTo, join, lowerCase, startCase} from 'lodash-es';
import {Observable, throwError} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Injectable()
export class ErrorMessageHelper {
  private translate: TranslateService;

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public getErrorMessageFromBackendError(backendErrorContext: BackendErrorContext): Observable<string> {
    let result: Observable<string>;

    switch (Number(BackendErrorCodeEnum[backendErrorContext.errorCode])) {
      case BackendErrorCodeEnum.NOT_FOUND:
        result = this.getErrorMessageForBackendErrorAndTranslationKey('GENERAL.ERRORS.BACKEND.NOT_FOUND', backendErrorContext);
        break;
      case BackendErrorCodeEnum.DUPLICATE_CONFIGURATION:
        result = this.getErrorMessageForDuplicateConfigurationBackendError(backendErrorContext);
        break;
      case BackendErrorCodeEnum.DUPLICATE_NAME:
        result = this.getErrorMessageForBackendErrorAndTranslationKey('GENERAL.ERRORS.BACKEND.DUPLICATE_NAME', backendErrorContext);
        break;
      case BackendErrorCodeEnum.LINKED_ENTITIES:
        result = this.getErrorMessageForLinkedEntitiesBackendError(backendErrorContext);
        break;
      default:
        result = throwError(backendErrorContext);
    }
    return result;
  }

  private getErrorMessageForBackendErrorAndTranslationKey(translationKey: string, backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate.get(this.getTranslationKeyForEntity(backendErrorContext.entity), {count: 1}).pipe(
      mergeMap((translatedEntity: string) => {
        return this.translate
          .get(translationKey, {
            entity: startCase(translatedEntity),
            entityName: backendErrorContext.entityName,
            entityNames: join(backendErrorContext.entityNames, ', ')
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getErrorMessageForDuplicateConfigurationBackendError(backendErrorContext: BackendErrorContext): Observable<string> {
    return this.translate.get(`TEXTILE_DATA.${backendErrorContext.entity}.${backendErrorContext.entity}`, {count: 1}).pipe(
      mergeMap((translatedEntity: string) => {
        return this.translate
          .get('GENERAL.ERRORS.BACKEND.DUPLICATE_CONFIGURATION', {
            entity: startCase(translatedEntity),
            similarEntityName: backendErrorContext.similarEntityName
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getErrorMessageForLinkedEntitiesBackendError(backendErrorContext: BackendErrorContext): Observable<string> {
    const entityTranslationKey = this.getTranslationKeyForEntity(backendErrorContext.entity);
    const linkedEntityTranslationKey = defaultTo(this.getTranslationKeyForEntity(backendErrorContext.linkedEntity), '');

    return this.translate.get([entityTranslationKey, linkedEntityTranslationKey], {count: 1}).pipe(
      mergeMap((translations: Record<string, string>) => {
        return this.translate
          .get('GENERAL.ERRORS.BACKEND.LINKED_ENTITIES', {
            entity: startCase(translations[entityTranslationKey]),
            entityName: backendErrorContext.entityName,
            linkedEntity: lowerCase(translations[linkedEntityTranslationKey]),
            linkedEntityNames: join(backendErrorContext.linkedEntityNames, ', ')
          })
          .pipe(map((translatedErrorMessage: string) => translatedErrorMessage));
      })
    );
  }

  private getTranslationKeyForEntity(entity: Entity): string {
    return {
      MACHINE_QUALITY: 'TEXTILE_DATA.MACHINE_QUALITY.MACHINE_QUALITY',
      COLOR_SET: 'TEXTILE_DATA.COLOR_SET.COLOR_SET',
      COLOR: 'TEXTILE_DATA.COLOR.COLOR',
      YARN_SET: 'TEXTILE_DATA.YARN_SET.YARN_SET',
      YARN_TYPE: 'TEXTILE_DATA.YARN_TYPE.YARN_TYPE',
      COLORED_YARN_SET: 'TEXTILE_DATA.COLORED_YARN_SET.COLORED_YARN_SET',
      CREEL: 'TEXTILE_DATA.CREEL.CREEL',
      FINISHING: 'TEXTILE_DATA.FINISHING.FINISHING',
      FINISHING_TEMPLATE: 'TEXTILE_DATA.FINISHING_TEMPLATE.FINISHING_TEMPLATE',
      MACHINE: 'MACHINE.MACHINE',
      DESIGN: 'DESIGN_LIBRARY.DESIGN',
      CUSTOM_SETTING: 'GENERAL.CUSTOM_SETTINGS.CUSTOM_SETTINGS',
      WEAVE_STRUCTURE: 'TEXTILE_DATA.WEAVE_STRUCTURE.WEAVE_STRUCTURE',
      COMPANY: 'GENERAL.COMPANY',
      PRODUCTION_ORDER: 'PRODUCTION_ORDER.PRODUCTION_ORDER',
      RUN: 'PLANNING.ADD_ITEM.TYPES.RUN'
    }[entity];
  }
}
