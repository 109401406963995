export enum Module {
  ROLE = 20001,
  USER = 20002,
  ORGANIZATIONAL_STRUCTURE = 20003,
  BROADCAST_MESSAGE = 20004,
  REGISTRATION = 30001,
  CUSTOMER = 30002,
  CONTACT_PERSON = 30003,
  CUSTOMER_PRICE_FORM = 30004,
  CUSTOM_SETTING = 40001,
  PRODUCT = 50001,
  SPARE_PART = 50002,
  PRICING = 50003,
  QUOTATION = 60001,
  CONTRACT = 60002,
  ORDER = 60003,
  PAYMENT = 60004,
  ORDER_DELIVERY = 60005,
  SALESFORCE = 70001,
  ERP = 80001
}

export const PREVIEW_ONLY_MODULES: Module[] = [];

export const INTEGRATION_MODULE = [Module.SALESFORCE];
