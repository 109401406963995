<div fxLayout="column" fxFlex fxLayoutAlign="center center" fxLayoutGap="8px">
  <img *ngIf="shouldDisplayImage" [src]="image" alt="illustration" [style.height.px]="scaled(heightImage)" />

  <h1 *ngIf="shouldDisplayTitle" [style.fontSize.px]="scaled(titleFontSize)">{{ title }}</h1>

  <p fxFlex *ngIf="shouldDisplayDescription" class="b1 description" [style.fontSize.px]="scaled(descriptionFontSize)">{{ description }}</p>

  <div fxFlex fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center" *ngIf="shouldDisplayActions">
    <div *ngFor="let action of actions">
      <button mat-flat-button fxFlex="initial" color="primary" *ngIf="getActionClass(action)" (click)="onActionClicked(action.key)">
        {{ action.titleKey | translate }}
      </button>
      <button mat-button fxFlex="initial" *ngIf="!getActionClass(action)" (click)="onActionClicked(action.key)">
        {{ action.titleKey | translate }}
      </button>
    </div>
  </div>
</div>
