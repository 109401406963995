import {IModulePermission} from '@application/directives/has-permission.directive';
import {ModulePermissions} from '@infrastructure/http/organization/role/module-permissions';
import {isNil} from 'lodash-es';

export class Subscription {
  private readonly _modulePermissions: ModulePermissions[];

  public constructor(modulePermissions: ModulePermissions[]) {
    this._modulePermissions = modulePermissions;
  }

  public get modulePermissions(): ModulePermissions[] {
    return this._modulePermissions;
  }

  public static fromLocalStorage(subscriptionJSONString: string): Subscription {
    return Subscription.fromJSON(JSON.parse(subscriptionJSONString));
  }

  public static fromJSON(subscriptionJSON: any): Subscription {
    const subscription = !isNil(subscriptionJSON.modulePermissions) ? new Subscription(subscriptionJSON.modulePermissions.map((x) => ModulePermissions.fromJSON(x))) : null;
    return subscription;
  }

  public toJSON(): JSON {
    return {
      modulePermissions: this._modulePermissions.map((x) => x.toJSON())
    } as any as JSON;
  }

  public toLocalStorage(): string {
    return JSON.stringify(this.toJSON());
  }

  public hasPermission(requiredModulePermission: IModulePermission): boolean {
    return (
      this._modulePermissions?.some(
        (mp: ModulePermissions) => mp.moduleId === requiredModulePermission?.module && mp.permissionIds.some((permissionId: number) => permissionId === requiredModulePermission?.requiredPermission)
      ) ?? false
    );
  }

  public isValid(): boolean {
    return !isNil(this.modulePermissions);
  }
}
