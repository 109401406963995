import {Injectable} from '@angular/core';
import {EnumUtils} from '@application/helper/enum/enum-utils';
import {LocalStorageService} from 'angular-2-local-storage';
import {includes, isEmpty, isNil, map} from 'lodash-es';
import {moment} from '../../common/moment';
import {TranslateService} from '../translate.service';
import {Language} from './language.enum';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public static readonly LOCAL_STORAGE_KEY = 'language';
  public static readonly AVAILABLE_LANGUAGES: string[] = [Language.ZH];
  public static readonly DEFAULT_LANGUAGE: string = Language.ZH;
  private static readonly EXPERIMENTAL_LANGUAGES = Language.ZH;

  private readonly translate: TranslateService;
  private readonly localStorage: LocalStorageService;

  public constructor(translate: TranslateService, localStorage: LocalStorageService) {
    this.translate = translate;
    this.localStorage = localStorage;
  }

  public initialize(): Promise<void> {
    let storedLanguage: string = this.localStorage.get(LanguageService.LOCAL_STORAGE_KEY);

    if (isNil(storedLanguage) || isEmpty(storedLanguage) || isNil(EnumUtils.getKeyFromValue(Language, storedLanguage))) {
      storedLanguage = LanguageService.DEFAULT_LANGUAGE;
      this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY, storedLanguage);
    }

    moment.locale(storedLanguage);
    return this.setLanguage(storedLanguage);
  }

  public changeLanguage(language: string): Promise<void> {
    this.localStorage.set(LanguageService.LOCAL_STORAGE_KEY, language);
    moment.locale(language);
    return this.setLanguage(language);
  }

  public getActiveLanguage(): string {
    return Language[this.translate.getLocale().language.toUpperCase()];
  }

  public getAvailableLanguages(): {key: string; label: string}[] {
    return map(LanguageService.AVAILABLE_LANGUAGES, (language: string) => {
      const key = EnumUtils.getKeyFromValue(Language, language);
      return {
        key: language,
        label: this.translate.instant(`GENERAL.LANGUAGE.${key}`)
      };
    });
  }

  public isExperimentalLanguage(language: string): boolean {
    return includes(LanguageService.EXPERIMENTAL_LANGUAGES, language);
  }

  private setLanguage(language: string): Promise<void> {
    return this.translate.setLocale({language: EnumUtils.getKeyFromValue(Language, language).toLowerCase()});
  }
}
