import {Permission} from '@domain/organization/role/permission.enum';
import {filter, isEmpty, isNil, some, sortBy} from 'lodash-es';
import {ModuleUtils} from '../module-utils';
import {Subscription} from '../subscription/subscription';
import {INavigationBarItem} from './navigation-bar-item';
import {NavigationBarRouteLinkItem} from './navigation-bar-route-link-item';

export class NavigationBarItemUtils {
  public static filterNavigationBarItemsByPermissionAndMobileAvailability(
    navigationBarItems: INavigationBarItem[],
    subscription: Subscription,
    isMobile: boolean,
    isPreviewMode: boolean
  ): INavigationBarItem[] {
    return filter(navigationBarItems, (navigationBarItem: NavigationBarRouteLinkItem) => {
      let hasRequiredPermission = false;
      if (isEmpty(navigationBarItem.subItems)) {
        hasRequiredPermission =
          (isPreviewMode && ModuleUtils.isPreviewOnly(navigationBarItem.route?.module)) ||
          isNil(navigationBarItem.modulePermission.requiredPermission) ||
          subscription?.hasPermission(navigationBarItem.modulePermission);
      } else {
        navigationBarItem.subItems = NavigationBarItemUtils.filterNavigationBarItemsByPermissionAndMobileAvailability(navigationBarItem.subItems, subscription, isMobile, isPreviewMode);
        hasRequiredPermission = some(
          navigationBarItem.subItems,
          (subItem: NavigationBarRouteLinkItem) =>
            (isPreviewMode && ModuleUtils.isPreviewOnly(subItem.route?.module)) || subscription?.hasPermission({module: subItem.modulePermission?.module, requiredPermission: Permission.VIEW})
        );
      }
      return hasRequiredPermission;
    });
  }

  public static sortNavigationBarItems(navigationBarItems: INavigationBarItem[]): INavigationBarItem[] {
    return sortBy(navigationBarItems, 'sort');
  }
}
