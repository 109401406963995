import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appInputChinese]'
})
export class InputChineseDirective {
  @HostListener('keypress', ['$event'])
  public onKeyPress(keyboardEvent: KeyboardEvent): void {
    if (this.isChineseCharacter(keyboardEvent.key)) {
      keyboardEvent.preventDefault();
    }
  }

  private isChineseCharacter(key: string): boolean {
    return /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF]+/g.test(key);
  }
}
