import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {NotificationOverviewResponse} from './notification-overview.response';

export const NOTIFICATION = new InjectionToken<IHttpNotificationService>('IHttpNotificationService');

export interface IHttpNotificationService {
  getAll(): Observable<NotificationOverviewResponse[]>;
  markAsRead(request: number[]): Observable<any>;
  getPaginated(startRow: number, endRow: number, column: string, sort: string): Observable<any>;
}
