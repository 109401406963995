import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-authentication-template',
  templateUrl: './authentication-template.component.html',
  styleUrls: ['./authentication-template.component.scss']
})
export class AuthenticationTemplateComponent {
  @Input() public showBackButton = false;
  @Input() public label = 'AUTHENTICATION.TITLE';
  @Input() public labelReplaceableValues = {};
  @Input() public illustrationName = '';

  public getIllustrationName(): string {
    return this.illustrationName;
  }

  public canShowBackButton(): boolean {
    return this.showBackButton;
  }
}
