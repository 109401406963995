import {Component, Input, OnChanges} from '@angular/core';
import {TranslateService} from '@application/translation/translate.service';
import {INoRowsOverlayAngularComp} from 'ag-grid-angular';
import {INoRowsOverlayParams} from 'ag-grid-community';
import {isNil, lowerCase} from 'lodash-es';
import {OverlayActionsService} from '../overlay/overlay-actions.service';
import {OverlayComponent} from '../overlay/overlay.component';
import {NoDataOverlayComponentParams} from './no-data-overlay-component-params';

@Component({
  selector: 'app-nodata-overlay',
  templateUrl: '../overlay/overlay.component.html',
  styleUrls: ['../overlay/overlay.component.scss']
})
export class NoDataOverlayComponent extends OverlayComponent implements INoRowsOverlayAngularComp, OnChanges {
  @Input() public shouldShowFilterNoResult = false;
  @Input() public filterDescriptionParam: any;
  @Input() public machineDisconnectedOverlay = false;
  private isAnyFilterPresent = false;
  private hasAgInitBeenCalled = false;

  public constructor(translate: TranslateService, actionsService: OverlayActionsService) {
    super(actionsService, translate);
  }

  public ngOnChanges(): void {
    if (!this.hasAgInitBeenCalled) {
      const params: NoDataOverlayComponentParams = <NoDataOverlayComponentParams>this.createParamsObject();
      params.filterDescriptionParam = this.filterDescriptionParam;
      params.isAnyFilterPresent = (): boolean => this.shouldShowFilterNoResult;

      this.initializeOverlayComponent(params);
      this.initializeNoDataOverlayComponent(params);
    }
  }

  public agInit(params): void {
    this.initializeOverlayComponent(params);
    this.initializeNoDataOverlayComponent(params);
    this.hasAgInitBeenCalled = true;
  }

  private initializeNoDataOverlayComponent(params: NoDataOverlayComponentParams): void {
    this.isAnyFilterPresent = isNil(params.isAnyFilterPresent) ? false : params.isAnyFilterPresent();

    if (this.isAnyFilterPresent) {
      this.image = '/assets/images/illustration/no-results-found.svg';
      this.title = this.getTranslation('OVERLAY.FILTER.NO_DATA.TITLE');
      this.description = this.getTranslation('OVERLAY.FILTER.NO_DATA.DESCRIPTION');
      this.actions = [];
    } else {
      this.image = '/assets/images/illustration/no-results.svg';
      this.title = this.getTranslation('OVERLAY.NO_DATA.TITLE_WITH_PARAM', params.titleParam);

      if (!params.hideDescription) {
        this.description = params.descriptionKey
          ? this.getTranslation(params.descriptionKey)
          : this.getTranslation('OVERLAY.NO_DATA.CREATE_OBJECT', lowerCase(this.translate.instant(String(params.titleParam))));
      }
    }
  }
}
