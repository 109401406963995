import {DOCUMENT} from '@angular/common';
import {Component, Inject, Input} from '@angular/core';
import {FormValidationHelper} from '@application/helper/form-validation-helper';
import {isEmpty} from 'lodash-es';

@Component({
  selector: 'app-invalid-form-field-message',
  templateUrl: './invalid-form-field-message.component.html',
  styleUrls: ['./invalid-form-field-message.component.scss']
})
export class InvalidFormFieldMessageComponent {
  @Input() public message = '';

  private formValidationHelper: FormValidationHelper = new FormValidationHelper();
  private readonly document;

  public constructor(@Inject(DOCUMENT) document: Document) {
    this.document = document;
  }

  public canShowMessage(): boolean {
    return !isEmpty(this.message);
  }

  public scrollToFirstInvalidFormField(): void {
    this.formValidationHelper.scrollToFirstInvalidAndTouchedFormField(this.document);
  }
}
