import {Pipe, PipeTransform} from '@angular/core';
import {merge, reduce} from 'lodash-es';
import {TranslateService} from '../translate.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  private readonly translate: TranslateService;

  public constructor(translate: TranslateService) {
    this.translate = translate;
  }

  public transform(value: any, ...args: any[]): any {
    return this.translate.instant(
      value,
      reduce(args, (mergedArgs, arg) => merge(mergedArgs, arg))
    );
  }
}
